import { Table } from "antd";
import React, { FC, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Metrics } from "../../../../models/Metrics/metrics.model";
import { Practice } from "../../../../models/Practice/practice.model";
import { Role } from "../../../../models/Role/role.model";
import { User } from "../../../../models/User/user.model";
import CorporateService from "../../../../services/Corporate/corporate.service";
import MetricsService from "../../../../services/Metrics/metrics.service";
import AppLoader from "../../../../shared/components/AppLoader";
import Stats from "../../../../shared/components/Stats";
import "./staffs.scss";

interface StaffsProps {}

const Staffs: FC<StaffsProps> = (props) => {
  const {} = props;

  const params = useParams<{ corporateId: string }>();
  const [metrics, setMetrics] = useState<Metrics>();

  const [staffs, setStaffs] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    CorporateService.fetchCorporateStaffs(
      params?.corporateId,
      (staffs) => setStaffs(staffs),
      () => {},
      () => {
        setLoading(false);
      }
    );
    MetricsService.fetchMetrics(
      params?.corporateId,
      "staffs",
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {}
    );
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (_: string, user: User) => (
        <span>
          {user?.firstName} {user?.lastName}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName",
    },
    {
      title: "Practice",
      dataIndex: "practice",
      key: "practice",
      render: (practice: Practice) => <span>{practice?.name}</span>,
    },
  ];
  const statsData = [
    {
      title: "Total Staffs",
      value: metrics?.totalStaffs ?? 0,
    },
    {
      title: "Total Doctors",
      value: metrics?.noOfDoctors ?? 0,
    },
    {
      title: "Total Assistants",
      value: metrics?.noOfAssistant ?? 0,
    },
  ];

  return (
    <div className="staffs">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <>
          <Stats statsData={statsData} />
          <Table dataSource={staffs} columns={columns} />
        </>
      )}
      ;
    </div>
  );
};

export default Staffs;
