import { Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import { PaymentIntent } from "../../../models/PaymentIntent/paymentIntent.model";
import PaymentService from "../../../services/Payment/payment.service";
import Stats from "../../../shared/components/Stats";
import moment from "moment";
import "./offlineTransaction.scss";

interface OfflineTransaction {}

const columns = [
  {
    title: "Patient ID",
    dataIndex: "contractPatientId",
    key: "contractPatientId",
  },
  {
    title: "Contract ID",
    dataIndex: "contractId",
    key: "contractId",
  },
  {
    title: "Transaction ID",
    dataIndex: "stripePaymentIntentId",
    key: "stripePaymentIntentId",
  },
  {
    title: "Corporate Name",
    dataIndex: "corporateName",
    key: "corporateName",
  },
  {
    title: "Practice Name",
    dataIndex: "practiceName",
    key: "practiceName",
  },
  {
    title: "Payment Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date: string) => <span>{moment(date).format("DD/MM/YYYY")}</span>,
  },
  {
    title: "Payment Method",
    dataIndex: "paymentType",
    key: "paymentType",
  },
  {
    title: "Status",
    dataIndex: "stripePaymentIntentStatus",
    key: "stripePaymentIntentStatus",
    render: (status: string) => (
      <span
        className={`${
          status === "paid" || status === "open"
            ? "text-success"
            : "text-warning"
        }`}
      >
        {status}
      </span>
    ),
  },
];

const OnlineTransaction: FC<OfflineTransaction> = (props) => {
  const {} = props;

  const [paymentIntents, setPaymentIntents] = useState<PaymentIntent[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    PaymentService.getOfflinePayments(
      (paymentIntents) => {
        setPaymentIntents(paymentIntents);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className="offline-transaction">
      <Stats />
      <Table dataSource={paymentIntents} columns={columns} loading={loading} />
    </div>
  );
};

export default OnlineTransaction;
