export const AppRoutes = {
  BASE_URL: process.env["REACT_APP_BASE_URL"],
  AUTH: "/auth",
  LOGIN: `https://aligner4dadb2c.b2clogin.com/aligner4dadb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_aligner4dSignInFlow&client_id=f300da0f-36c4-4b04-8e2b-74b02c17d132&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_BASE_URL}/auth&scope=openid&response_type=code&prompt=login`,
  HOME: "/",
  DASHBOARD: "/dashboard",
  CORPORATES: "/corporates",
  PENDING_CORPORATE_DETAIL: "/pending-corporates/:corporateId",
  CORPORATE_DETAIL: "/corporates/:corporateId",
  SUBSCRIPTIONS: "/subscriptions",
  PAYMENTS: "/payments",
  XD_ANALYTICS: "/xd-analytics",
  CORE_3D: "/core-3d",
  OBJECT_3D: "/core-3d/objects",
  ADD_OBJECT_3D: "/core-3d/objects/add",
  EDIT_OBJECT_3D: "/core-3d/objects/edit/:dentalObjectId",
  CLONE_OBJECT_3D: "/core-3d/objects/clone/:dentalObjectId",
  DENTAL_OBJECT_DETAIL: "/core-3d/:dentalObjectId",
  OBJECT_LINKS: "/core-3d/object-links",
  ADD_OBJECT_LINK: "/core-3d/object-links/:dentalObjectId/add",
  SUPPORT: "/support",
  SETTINGS: "/settings",
  USERS: "/settings/users",
  ROLES_AND_PERMISSIONS: "/settings/roles-and-permissions",
};
