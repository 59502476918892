import { Col, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useRef } from "react";
import { Support } from "../../../../models/Support/support.model";
import PDF from "../../../../assets/images/pdf.png";
import "./attachments.scss";
import { Attachment } from "../../../../models/Attachment/attachment.mode";
import SupportService from "../../../../services/Support/support.service";

interface AttachmentsProps {
  support?: Support;
}

const Attachments: FC<AttachmentsProps> = (props) => {
  const { support } = props;

  const [loading, setLoading] = useState(false);

  const [attachments, setAttachments] = useState<Attachment[]>([]);

  useEffect(() => {
    SupportService.viewAttachments(
      support?.id ?? 0,
      (attachments) => {
        setAttachments(attachments);
      },
      () => {}
    );
  }, []);

  return (
    <div className="attachments">
      {attachments.map(({ attachmentUrl, attachment }) =>
        attachmentUrl ? (
          <Row className="list__attachments" align="middle">
            <Col span={2}>
              <img src={PDF} alt="" height="48px" />
            </Col>
            <Col span={22}>
              <a href={attachmentUrl} target="_blank">
                {attachment}
              </a>
            </Col>
          </Row>
        ) : null
      )}
    </div>
  );
};

export default Attachments;
