import { Button, Drawer } from "antd";
import React, { FC } from "react";
import "./rolesAndResponsibility.scss";
import UserResponsibility from "./UserResponsibility";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import UserRoleForm from "./UserRoleForm";
import { useEffect } from "react";
import { RoleService } from "../../../services/Role/role.service";
import { Role } from "../../../models/Role/role.model";
import AppLoader from "../../../shared/components/AppLoader";

interface RolesAndResponsibilityProps {}

const RolesAndResponsibility: FC<RolesAndResponsibilityProps> = (props) => {
  const {} = props;

  const [showUser, setShowUser] = useState(false);

  const [roles, setRoles] = useState<Role[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    RoleService.getRoles(
      (roles) => {
        setRoles(roles);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  const handleOpen = () => {
    setShowUser(true);
  };

  const handleClose = () => setShowUser(false);

  const handleAddRole = (role: Role) => {
    setRoles((roles) => [...roles, role]);
    handleClose();
  };

  const handleUpdateRole = (role: Role) => {
    const updatedRole = Object.assign(new Role(), role);
    RoleService.updateRole(
      updatedRole,
      (updatedRole) => {
        setRoles((roles) =>
          roles.map((role) => {
            if (role.id === updatedRole.id) {
              return updatedRole;
            }
            return role;
          })
        );
      },
      () => {}
    );
  };

  return (
    <div className="roles-and-responsibility">
      <div className="head mb-5">
        <p>All Roles</p>
        <Button type="primary" onClick={handleOpen}>
          <PlusOutlined /> Add Role
        </Button>
      </div>
      {loading ? (
        <AppLoader loading />
      ) : (
        roles.map((role) => (
          <UserResponsibility
            role={role}
            updateRoleHandler={handleUpdateRole}
          />
        ))
      )}
      <Drawer
        visible={showUser}
        destroyOnClose
        onClose={handleClose}
        width="45vw"
        title="User Role"
      >
        <UserRoleForm
          successHandler={handleAddRole}
          closeHandler={handleClose}
        />
      </Drawer>
    </div>
  );
};

export default RolesAndResponsibility;
