import { Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import { PaymentTransfer } from "../../../models/PaymentTransfer/paymentTransfer.model";
import PaymentService from "../../../services/Payment/payment.service";
import Stats from "../../../shared/components/Stats";
import moment from "moment";
import "./settlements.scss";

interface SettlementsProps {}

const columns = [
  {
    title: "Patient ID",
    dataIndex: "",
    key: "",
    render: (_: any, paymentTransfer: PaymentTransfer) =>
      paymentTransfer?.paymentIntent?.contractPatientId,
  },
  {
    title: "Contract ID",
    dataIndex: "contractId",
    key: "contractId",
    render: (_: any, paymentTransfer: PaymentTransfer) =>
      paymentTransfer?.paymentIntent?.contractId,
  },
  {
    title: "Corporate Name",
    dataIndex: "corporateName",
    key: "corporateName",
    render: (_: any, paymentTransfer: PaymentTransfer) =>
      paymentTransfer?.paymentIntent?.corporateId,
  },
  {
    title: "Transaction ID",
    dataIndex: "stripePaymentIntentId",
    key: "stripePaymentIntentId",
    render: (_: any, paymentTransfer: PaymentTransfer) =>
      paymentTransfer?.paymentIntent?.stripePaymentIntentId,
  },
  {
    title: "Settled Amount",
    dataIndex: "stripeTransferAmount",
    key: "stripeTransferAmount",
    render: (_: any, paymentTransfer: PaymentTransfer) =>
      `$${paymentTransfer?.paymentIntent?.stripePaymentIntentAmount}`,
  },
  {
    title: "Payment Date",
    dataIndex: "paymentDate",
    key: "paymentDate",
    render: (_: any, paymentTransfer: PaymentTransfer) =>
      moment(paymentTransfer?.paymentIntent?.createdAt).format("DD/MM/YYYY"),
  },
  {
    title: "Status",
    dataIndex: "stripeTransferStatus",
    key: "stripeTransferStatus",
    render: (_: string, paymentTransfer: PaymentTransfer) => (
      <span
        className={`${
          paymentTransfer?.paymentIntent?.stripePaymentIntentStatus ===
            "paid" ||
          paymentTransfer?.paymentIntent?.stripePaymentIntentStatus === "open"
            ? "text-success"
            : "text-warning"
        }`}
      >
        {paymentTransfer?.paymentIntent?.stripePaymentIntentStatus}
      </span>
    ),
  },
];

const Settlements: FC<SettlementsProps> = (props) => {
  const {} = props;

  const [paymentTransfers, setPaymentTransfers] = useState<PaymentTransfer[]>(
    []
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    PaymentService.getPaymentTransfers(
      (paymentTransfers) => {
        setPaymentTransfers(paymentTransfers);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className="settlements">
      <Stats />
      <Table
        dataSource={paymentTransfers}
        columns={columns}
        loading={loading}
      />
    </div>
  );
};

export default Settlements;
