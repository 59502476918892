export enum StatsTypeEnum {
  SERVICE = "service",
  CONTRACT_TERM_TEMPLATES = "contract_term_template",
  CONTRACT = "contract",
  PATIENTS = "patients",
  PAYMENT = "payment",
  SUPPORT = "support",
  CUSTOM_FORMS = "custom_forms",
  PRACTICE = "practice",
  STAFF = "staff",
  TREATMENT_EXECUTION_PLANS = "treatment_execution_plan",
  PROCEDURE = "procedure",
  SET_LIST = "set_list",
  RESOURCES = "resources",
  APPOINTMENT = "appointment",
}
