import React, { useState } from "react";
import "./corporatesWrapper.scss";
import { Button, Col, Row, Tabs, Input, Drawer } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CorporateList from "../../../shared/components/CorporateList";
import { CorporateStatusEnum } from "../../../enums/corporateStatus.enum";
import CorporateForm from "../CorporateForm";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsibility.enum";
import Stats from "../../../shared/components/Stats";
import { StatsTypeEnum } from "../../../enums/statsType.enum";

const { TabPane } = Tabs;

const { Search } = Input;

interface CorporatesWrapperProps extends AuthReducerProps {}

function CorporatesWrapper({ userResponsibilities }: CorporatesWrapperProps) {
  const [showCorporateForm, setShowCorporateForm] = useState(false);

  const handleShowCorporateForm = () => setShowCorporateForm(true);

  const handleCloseCorporateForm = () => setShowCorporateForm(false);

  const handleFormSuccess = () => {
    setShowCorporateForm(false);
  };

  const handleSearch = (value: string) => {};

  return (
    <div className="corporates-wrapper">
      <Row className="corporates-wrapper__add-corporate mb-5">
        <Col span={12}>
          <Search
            className="corporates-wrapper__search"
            placeholder="Search"
            onSearch={handleSearch}
            style={{ width: 250 }}
          />
        </Col>
        <Col span={12} className="text-right">
          {userResponsibilities.includes(
            ResponsibilityTypes.CORPORATE_CREATE
          ) && (
            <Button type="primary" onClick={handleShowCorporateForm}>
              <PlusOutlined />
              New Corporate
            </Button>
          )}
        </Col>
      </Row>

      <div className="mt-5 corporates-wrapper__corporate-list">
        <Tabs defaultActiveKey="1" size="small" style={{ marginBottom: 32 }}>
          <TabPane tab="Approved Corporates" key="1">
            <CorporateList status={CorporateStatusEnum.APPROVED} />
          </TabPane>
          <TabPane tab="Rejected Corporates" key="2">
            <CorporateList status={CorporateStatusEnum.REJECTED} />
          </TabPane>
        </Tabs>
      </div>
      <Drawer
        title={<h4>New corporate</h4>}
        placement="right"
        maskClosable={false}
        onClose={handleCloseCorporateForm}
        visible={showCorporateForm}
        destroyOnClose={true}
        width="50%"
      >
        <CorporateForm
          onSuccess={handleFormSuccess}
          onCancel={handleCloseCorporateForm}
        />
      </Drawer>
    </div>
  );
}

export default AuthContainer(CorporatesWrapper);
