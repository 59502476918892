import * as Yup from "yup";
import {
  validateNumber,
  validateString,
} from "../../../shared/utils/validatorUtils";

export const dentalObjectFormValidation = Yup.object().shape({
  name: validateString("Name is required", true),
  friendlyName: validateString("Friendly name is required", true),
  objectType: validateString("Type is required", true),
  objectCategoryId: validateNumber("Category is required", true),
  objectModelId: validateNumber("Model is required", true),
  manufacturerId: validateNumber("Manufacturer is required", true),
  description: validateString("Description is required", true),
  color: validateString("Color is required", true),
  attachment: Yup.mixed().when("attachmentUrl", {
    is: undefined,
    then: Yup.mixed().required("Attachment is required"),
    otherwise: Yup.mixed(),
  }),
});
