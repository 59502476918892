import React, { FC, Fragment } from "react";
import { useState } from "react";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { responsibilities } from "./data";
import "./userResponsibility.scss";
import { Checkbox, Col, Row } from "antd";
import { Role } from "../../../../models/Role/role.model";
import AppLoader from "../../../../shared/components/AppLoader";
import { RoleService } from "../../../../services/Role/role.service";

interface UserResponsibilityProps {
  role: Role;
  updateRoleHandler: (role: Role) => void;
}

const UserResponsibility: FC<UserResponsibilityProps> = (props) => {
  const {
    role,
    updateRoleHandler,
  } = props;

  const { name, description, permissions } = role;

  const [selectedCard, setSelectedCard] = useState(0);

  const [content, setContent] = useState<
    { id: number, title: string; approval: string[] }[]
  >(responsibilities[0].responsibility);

  const [className, setClassName] = useState<"content-open" | "content-close">(
    "content-open"
  );

  const handleOpen = () => {
    setClassName((className) => {
      if (className === "content-open") {
        return "content-close";
      }
      return "content-open";
    });
  };

  const handleUpdateResponsibility = (
    checked: boolean,
    permission: string
  ) => {
    let updatedRole = {};
    if(checked) {
      updatedRole = {
        ...role,
        permissions: [...role.permissions, permission]
      }
    } else {
      const updatedPermissions = [...role.permissions];
      const index = role.permissions.findIndex(p => p === permission);
      updatedPermissions.splice(index, 1);
      updatedRole = {
        ...role,
        permissions: updatedPermissions
      }
    }
    updateRoleHandler(updatedRole as Role);
  };

  const isPresent = (
    approvals: string[],
    hasPermission: "create" | "view" | "edit" | "delete"
  ) => {
    for (let approval of approvals) {
      if (approval.includes(hasPermission)) {
        return (
          <Checkbox
            defaultChecked={permissions?.includes(approval)}
            onChange={(e) =>
              handleUpdateResponsibility(e.target.checked, approval)
            }
          />
        );
      }
    }
    return null;
  };

  return (
    <div className="user-responsibility" onClick={handleOpen}>
      <div className="head">
        <span>
          <p className="title">{name}</p>
          <p className="description">{description}</p>
        </span>
        {className === "content-open" ? <UpOutlined /> : <DownOutlined />}
      </div>
      <div className={`content ${className}`}>
        <Row gutter={[15, 0]}>
          <Col span={6}>
            {responsibilities.map(({ id, title, responsibility }, index) => {
              const handleSelect = () => {
                setContent(responsibility);
                setSelectedCard(index);
              };
              return (
                <div
                  className={`card ${
                    selectedCard === index && "card-selected"
                  }`}
                  key={id}
                  onClick={handleSelect}
                >
                  {title}
                </div>
              );
            })}
          </Col>
          <Col span={18}>
            <Row justify="space-between" className="mb-5">
              <Col span={8}></Col>
              <Col span={4}>Create</Col>
              <Col span={4}>View</Col>
              <Col span={4}>Edit</Col>
              <Col span={4}>Delete</Col>
            </Row>
            {content.map(({ id, title, approval }) => (
              <Row justify="space-between" className="mb-5" key={id}>
                <Col span={8}>{title}</Col>
                <Col span={4}>{isPresent(approval, "create")}</Col>
                <Col span={4}>{isPresent(approval, "view")}</Col>
                <Col span={4}>{isPresent(approval, "edit")}</Col>
                <Col span={4}>{isPresent(approval, "delete")}</Col>
              </Row>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UserResponsibility;
