import React, { useEffect, useState } from "react";
import "./attachmentUpload.scss";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";
import { Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import FileDetail from "../FileDetail";
import { ChangeEvent } from "jest-haste-map/build/types";

interface AttachmentUploadProps {
  name: string;
  title?: string;
  placeholder: string;
  multiple?: boolean;
  accept: string;
  attachments: string[];
  newAttachments: File[];
  setFieldValue: Function;
  onChange?: (event: any) => void;
}

function AttachmentUpload(props: AttachmentUploadProps) {
  let inputRef: any;

  const {
    name,
    title,
    placeholder,
    multiple,
    onChange,
    attachments,
    setFieldValue,
    newAttachments,
    accept,
  } = props;

  const [newAttachmentUrls, setNewAttachmentUrls] = useState<string[]>([]);

  const handleClick = () => {
    inputRef?.click();
  };

  const handleChange = (e: any) => {
    if (multiple) {
      const files: File[] = newAttachments.concat(Array.from(e.target.files));
      setFieldValue(name, files);
    } else {
      setFieldValue(name, e.target.files[0]);
    }
  };

  useEffect(() => {
    const attachmentUrls: string[] = [];
    if (newAttachments.length > 0) {
      newAttachments.forEach((file: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          if (typeof reader.result === "string") {
            attachmentUrls.push(reader.result);
            setNewAttachmentUrls([...attachmentUrls]);
          }
        };
        reader.onerror = function (error) {};
      });
    } else {
      setNewAttachmentUrls([]);
    }
  }, [newAttachments]);

  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className="attachment-upload">
          {title && <div className="attachment-upload__title">{title}</div>}
          <Row className="attachment-upload__attachment-list" gutter={[15, 15]}>
            <Col span={24} className="attachment-upload__img-wrapper">
              <div className="attachment-upload__input" onClick={handleClick}>
                <UploadOutlined className="mr-2" />
                {placeholder}
              </div>
              <input
                ref={(ref) => (inputRef = ref)}
                type="file"
                multiple={multiple}
                onChange={onChange || handleChange}
                accept={accept}
                style={{ display: "none" }}
              />
            </Col>
            {newAttachmentUrls?.map((attachmentUrl: string, i: number) => {
              return (
                <Col
                  span={6}
                  className="attachment-upload__img-wrapper"
                  key={i}
                >
                  {attachmentUrl.startsWith("data:image") ? (
                    <img
                      src={attachmentUrl}
                      className="attachment-upload__img"
                      alt="Attachment"
                    />
                  ) : (
                    <FileDetail name={newAttachments[i].name} />
                  )}
                </Col>
              );
            })}
          </Row>
          <ErrorMessage name={name}>
            {(message: string) => <Error message={message} />}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
}

export default AttachmentUpload;
