import { serializable, alias, primitive, object } from "serializr";
import { Role } from "../Role/role.model";

export class User {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("dob", primitive()))
  dob?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("country_code", primitive()))
  countryCode?: string;

  @serializable(alias("country_id", primitive()))
  countryId?: string;

  @serializable(alias("mobile_number", primitive()))
  mobileNumber?: string;

  @serializable(alias("country", primitive()))
  country?: string;

  @serializable(alias("user_role", primitive()))
  userRole?: string;

  @serializable(alias("role_id", primitive()))
  roleId?: number;

  @serializable(alias("role_name", primitive()))
  roleName?: string;

  @serializable(alias("street", primitive()))
  street?: string;

  @serializable(alias("province_id", primitive()))
  provinceId?: string;

  @serializable(alias("province_name", primitive()))
  provinceName?: string;

  @serializable(alias("city_id", primitive()))
  cityId?: number;

  @serializable(alias("city_name", primitive()))
  cityName?: string;

  @serializable(alias("building", primitive()))
  building?: string;

  @serializable(alias("zip_code", primitive()))
  zipcode?: string;

  @serializable(alias("role", object(Role)))
  role?: Role;
}
