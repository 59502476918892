import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import * as AuthFunctions from "../actions/auth";

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        ...ownProps,
        authenticated: state.auth.authenticated,
        user: state.auth.user,
        userResponsibilities: state.auth.userResponsibilities,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(AuthFunctions, dispatch);

const AuthContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

export default AuthContainer;