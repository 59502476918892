import pdfIcon from "../../assets/images/pdfIcon.png";
import docIcon from "../../assets/images/docIcon.png";
import excelIcon from "../../assets/images/excelIcon.png";
import fileIcon from "../../assets/images/fileIcon.png";
import imageIcon from "../../assets/images/imageIcon.png";

export const getFileIcon = (name: string): string => {
  const fileSplit = name.split(".");
  const type = fileSplit[fileSplit.length - 1];
  switch (type.toLowerCase()) {
    case "jpg" || "jpeg" || "png": {
      return imageIcon;
    }
    case "pdf": {
      return pdfIcon;
    }
    case "doc" || "docx": {
      return docIcon;
    }
    case "xls" || "xlsx" || "csv": {
      return excelIcon;
    }
    default:
      return fileIcon;
  }
};
