import React, { useEffect, useState } from "react";
import { Row, Col, Button, Table } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import AppLoader from "../../../shared/components/AppLoader";
import { Link, useHistory, generatePath } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import "./dentalObjectList.scss";
import { DentalObject } from "../../../models/DentalObject/dentalObject.model";
import DentalObjectService from "../../../services/DentalObject/dentalObject.service";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsibility.enum";

interface DentalObjectListProps extends AuthReducerProps {}

function DentalObjectList({ userResponsibilities }: DentalObjectListProps) {
  const history = useHistory();

  const [objects, setObjects] = useState<DentalObject[]>([]);

  const [loading, setLoading] = useState(false);

  const handleRow = (
    dentalObject: DentalObject,
    rowIndex: number | undefined
  ) => {
    return {
      onClick: (event: any) => {
        if (
          userResponsibilities.includes(ResponsibilityTypes.DENTAL_OBJECT_VIEW)
        ) {
          history.push(
            generatePath(AppRoutes.DENTAL_OBJECT_DETAIL, {
              dentalObjectId: dentalObject.id,
            })
          );
        }
      },
    };
  };

  useEffect(() => {
    setLoading(true);
    DentalObjectService.fetchDentalObjects(
      "",
      (objects: DentalObject[]) => {
        setObjects(objects);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: "Friendly Name",
      dataIndex: "friendlyName",
      key: "friendlyName",
    },
    {
      title: "Model",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No of Links",
      dataIndex: "noOfLinks",
      key: "noOfLinks",
    },
    {
      title: "Type",
      dataIndex: "objectType",
      key: "objectType",
    },
    {
      title: "Category",
      dataIndex: "objectCategoryName",
      key: "objectCategoryName",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (text: string, record: any, index: number) => {
        if (
          !userResponsibilities.includes(ResponsibilityTypes.DENTAL_OBJECT_EDIT)
        ) {
          return null;
        }
        return (
          <div>
            <Link
              onClick={(e) => e.stopPropagation()}
              to={generatePath(AppRoutes.EDIT_OBJECT_3D, {
                dentalObjectId: record.id,
              })}
            >
              <Button className="bg-primary" icon={<EditOutlined />} />
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className="dental-object-list">
      <Row align="middle">
        <Col span={12}>
          <h5 className="text-secondary">All objects</h5>
        </Col>
        <Col span={12} className="text-right">
          {userResponsibilities.includes(
            ResponsibilityTypes.DENTAL_OBJECT_CREATE
          ) && (
            <Link to={AppRoutes.ADD_OBJECT_3D}>
              <Button type="primary">
                <PlusOutlined />
                Create Object
              </Button>
            </Link>
          )}
        </Col>
      </Row>
      <Row>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table dataSource={objects} columns={columns} onRow={handleRow} />
        )}
      </Row>
    </div>
  );
}

export default AuthContainer(DentalObjectList);
