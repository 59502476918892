import axiosInstance from "../../interceptor/axiosInstance";
import Subscription from "../../models/Subscription/subscription.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { PaymentIntent } from "../../models/PaymentIntent/paymentIntent.model";
import { PaymentTransfer } from "../../models/PaymentTransfer/paymentTransfer.model";
import { SubscriptionPayment } from "../../models/SubscriptionPayment/subscriptionPayment.model";

export default class PaymentService {
  static async getSubscriptionPayments(
    onSuccess: (subscriptions: SubscriptionPayment[]) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.LIST_SUBSCRIPTION_PAYMENTS);
      const subscriptions = deserialize(
        SubscriptionPayment,
        data?.subscription_payments as SubscriptionPayment[]
      );
      onSuccess(subscriptions);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async getPaymentIntents(
    onSuccess: (paymentIntents: PaymentIntent[]) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.LIST_PAYMENT_INTENTS);
      const paymentIntents = deserialize(
        PaymentIntent,
        data?.payment_intents as PaymentIntent[]
      );
      onSuccess(paymentIntents);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async getPaymentTransfers(
    onSuccess: (paymentTransfers: PaymentTransfer[]) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(
        ApiRoutes.LIST_PAYMENT_TRANSFERS
      );
      const paymentTransfers = deserialize(
        PaymentTransfer,
        data?.payment_transfers as PaymentTransfer[]
      );
      onSuccess(paymentTransfers);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async getOfflinePayments(
    onSuccess: (paymentIntents: PaymentIntent[]) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(
        ApiRoutes.LIST_OFFLINE_PAYMENTS
      );
      const paymentIntents = deserialize(
        PaymentTransfer,
        data?.payment_intents as PaymentIntent[]
      );
      onSuccess(paymentIntents);
    } catch (error) {
    } finally {
      onFinal();
    }
  }
}
