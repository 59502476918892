import React, { useEffect, useState } from "react";
import "./corporateDetail.scss";
import { Row, Col, Button } from "antd";
import { Corporate } from "../../../models/Corporate/corporate.model";
import CorporateService from "../../../services/Corporate/corporate.service";
import { useParams, Link } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsibility.enum";
import Modal from "antd/lib/modal/Modal";
import { Worker } from "@react-pdf-viewer/core";
// Import the main component
import { Viewer } from "@react-pdf-viewer/core";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
interface CorporateDetailProps extends AuthReducerProps {
  allowVerify?: boolean;
  onVerify?: () => void;
}

function CorporateDetail(props: CorporateDetailProps) {
  const [corporate, setCorporate] = useState(new Corporate());

  const { allowVerify, onVerify, userResponsibilities } = props;

  const [pdfPreview, setPdfPreview] = useState(false);

  const [viewLogo, setViewLogo] = useState(false);

  const { corporateId } = useParams();

  useEffect(() => {
    CorporateService.showCorporate(
      corporateId,
      (corporate: Corporate) => {
        setCorporate(corporate);
      },
      () => {},
      () => {}
    );
  }, []);

  return (
    <div className="corporate-detail">
      <div className="corporate-detail__header">
        Corporates / <span className="text-secondary">32 Dental care</span>
      </div>
      <div className="corporate-detail__body">
        <Row gutter={[10, 30]}>
          <Col span={10}>
            <label className="corporate-detail__label">Corporate Name</label>
            <div className="corporate-detail__value">
              {corporate?.name || "NA"}
            </div>
          </Col>
          <Col span={14}>
            <label className="corporate-detail__label">Address Line 1</label>
            <div className="corporate-detail__value">
              {corporate?.addressLine1 || "NA"}
            </div>
          </Col>
          <Col span={10}>
            <label className="corporate-detail__label">Address Line 2</label>
            <div className="corporate-detail__value">
              {corporate?.addressLine2 || "NA"}
            </div>
          </Col>
          <Col span={14}>
            <label className="corporate-detail__label">Province</label>
            <div className="corporate-detail__value">
              {corporate?.provinceName || "NA"}
            </div>
          </Col>
          <Col span={10}>
            <label className="corporate-detail__label">City</label>
            <div className="corporate-detail__value">
              {corporate?.cityName || "NA"}
            </div>
          </Col>
          <Col span={14}>
            <label className="corporate-detail__label">Zipcode</label>
            <div className="corporate-detail__value">
              {corporate?.zipCode || "NA"}
            </div>
          </Col>
          <Col span={10}>
            <label className="corporate-detail__label">Nationality</label>
            <div className="corporate-detail__value">
              {corporate?.nationality || "NA"}
            </div>
          </Col>
          <Col span={14}>
            <label className="corporate-detail__label">Website</label>
            <div className="corporate-detail__value">
              {corporate?.websiteUrl || "NA"}
            </div>
          </Col>
          <Col span={10}>
            <label className="corporate-detail__label">Linkedin</label>
            <div className="corporate-detail__value">
              {corporate?.linkedinUrl || "NA"}
            </div>
          </Col>
          <Col span={14}>
            <label className="corporate-detail__label">Facebook</label>
            <div className="corporate-detail__value">
              {corporate?.facebookUrl || "NA"}
            </div>
          </Col>
          <Col span={10}>
            <label className="corporate-detail__label">Twitter</label>
            <div className="corporate-detail__value">
              {corporate?.twitterUrl || "NA"}
            </div>
          </Col>
          <Col span={14}>
            <label className="corporate-detail__label">Corporate Logo</label>
            <div className="corporate-detail__value">
              {corporate?.logoUrl ? (
                <img
                  className="corporate-detail__logo cursor-pointer"
                  src={corporate?.logoUrl}
                  alt="Corporate logo"
                  onClick={() => setViewLogo(true)}
                />
              ) : (
                "NA"
              )}
            </div>
          </Col>
          <Col span={10} className="attachment">
            <label className="corporate-detail__label">Attachment</label>
            <div className="corporate-detail__value">
              <div
                className="attachment__full-view"
                onClick={() => setPdfPreview(true)}
              />
              {corporate?.licenseUrl ? (
                <iframe
                  src={corporate?.licenseUrl}
                  className="corporate-detail__attachment cursor-pointer"
                  onClick={() => window.open(corporate?.licenseUrl, "_blank")}
                />
              ) : (
                "NA"
              )}
            </div>
          </Col>
        </Row>
        {userResponsibilities.includes(
          ResponsibilityTypes.CORPORATE_APPROVAL_EDIT
        ) && (
          <div className="text-right">
            <Link to={AppRoutes.CORPORATES}>
              <Button type="default" className="large-btn">
                Cancel
              </Button>
            </Link>
            <Button
              type="primary"
              className="ml-2 large-btn"
              onClick={onVerify}
            >
              Verify
            </Button>
          </div>
        )}
      </div>
      <Modal
        visible={pdfPreview}
        onCancel={() => setPdfPreview(false)}
        className="plain-view"
        destroyOnClose
        closable
        width="50vw"
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer fileUrl={corporate?.licenseUrl ?? ""} />
        </Worker>
      </Modal>
      <Modal
        visible={viewLogo}
        onCancel={() => setViewLogo(false)}
        className="plain-view"
        destroyOnClose
        closable
        width="60vw"
      >
        <img src={corporate?.logoUrl} width="100%" height="100%" />
      </Modal>
    </div>
  );
}

export default AuthContainer(CorporateDetail);
