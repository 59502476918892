import React from "react";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface AppLoaderProps {
    loading: boolean;
}

function AppLoader(props: AppLoaderProps) {

    const {loading} = props;

    return (
        <Spin indicator={<LoadingOutlined spin/>} size='large' spinning={loading}/>
    );
}

export default AppLoader;
