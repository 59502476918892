import React, { useEffect } from "react";
import "./authWrapper.scss";
import { useHistory } from "react-router-dom";
import AuthService from "../../../services/Auth/auth.service";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";

const AuthWrapper = () => {
  const history = useHistory();
  useEffect(() => {
    const code = new URLSearchParams(history.location.search).get("code") || "";
    if (code) {
      AuthService.login(
        code,
        () => {
          history.push(AppRoutes.HOME);
        },
        () => {},
        () => {}
      );
    }
  }, []);
  return <div className="auth-wrapper"> Loading...</div>;
};

export default AuthWrapper;
