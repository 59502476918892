import { object, string } from "yup";

export const userFormValidation = object().shape({
  firstName: string().required("First name is required"),
  lastName: string().required("Last name is required"),
  gender: string().required("Gender is required"),
  dob: string().required("DOB is required"),
  mobileNumber: string().required("Mobile is required"),
  email: string().required("Email is required").email("Enter a valid email"),
  countryId: string().required("Country is required"),
  provinceId: string().required("State/Province is required"),
  cityId: string().required("City is required"),
  street: string().required("Street is required"),
  building: string().required("Building is required"),
  zipcode: string().required("Postal Code is required"),
//   roleId: string().required("Role is required"),
});
