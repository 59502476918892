import { Tabs } from "antd";
import React, { FC } from "react";
import SubscriptionList from "./SubscriptionList";
import OnlineTransaction from "./OnlineTransaction";
import OfflineTransaction from "./OfflineTransaction";
import Settlements from "./Settlements";

import "./payments.scss";

const { TabPane } = Tabs;

interface PaymentsProps {}

const Payments: FC<PaymentsProps> = (props) => {
  const {} = props;

  const PaymentTabs = [
    /*  {
      title: "Corporate Payments",
      component: <SubscriptionList />,
    },
    {
      title: "Patient Payments",
      component: <SubscriptionList />,
    }, */
    {
      title: "Patient Payments",
      component: <OnlineTransaction />,
    },
    {
      title: "Subscription",
      component: <SubscriptionList />,
    },
    {
      title: "Online Transaction",
      component: <OnlineTransaction />,
    },
    {
      title: "Offline Transaction",
      component: <OfflineTransaction />,
    },
    {
      title: "Settlements",
      component: <Settlements />,
    },
  ];

  return (
    <div className="payments">
      <Tabs defaultActiveKey="0" style={{ marginBottom: 32 }} size="small">
        {PaymentTabs?.map(({ title, component }, index) => (
          <TabPane tab={title} key={index}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default Payments;
