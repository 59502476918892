import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Corporate } from "../../models/Corporate/corporate.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { CorporateVerificationEnum } from "../../enums/corporateVerification.enum";
import Patients from "../../views/Corporates/CorporateDetails/Patients";
import { Patient } from "../../models/Patient/patient.model";
import { User } from "../../models/User/user.model";
import { Practice } from "../../models/Practice/practice.model";

export default class CorporateService {
  static fetchCorporates(
    status: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.CORPORATES, { params: { status } })
      .then((response) => {
        const corporates = deserialize(Corporate, response.data["corporates"]);
        onSuccess(corporates);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showCorporate(
    corporateId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CORPORATE_DETAIL.replace(
      ":corporateId",
      corporateId
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const corporate = deserialize(Corporate, response.data["corporate"]);
        onSuccess(corporate);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static verifyCorporate(
    status: CorporateVerificationEnum,
    corporate: Corporate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const corporateJSON = { corporate: serialize(corporate) };
    let API_URL =
      status === CorporateVerificationEnum.APPROVE
        ? ApiRoutes.APPROVE_CORPORATE.replace(
            ":corporateId",
            corporate.id || ""
          )
        : ApiRoutes.REJECT_CORPORATE.replace(
            ":corporateId",
            corporate.id || ""
          );
    axiosInstance
      .put(API_URL, corporateJSON)
      .then((response) => {
        Notification({
          message:
            status === CorporateVerificationEnum.APPROVE
              ? "Corporate approved!"
              : "Corporate rejected",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static inviteCorporate(
    corporate: Corporate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const corporateJSON = { invite: serialize(corporate) };
    axiosInstance
      .post(ApiRoutes.INVITES, corporateJSON)
      .then((response) => {
        Notification({
          message: "Corporate invited!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static async fetchCorporatePatients(
    corporateId: string,
    onSuccess: (patients: Patient[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(
        ApiRoutes.CORPORATE_PATIENTS.replace(":corporateId", corporateId)
      );
      const patients = deserialize(Patient, data["patients"] as Patient[]);
      onSuccess(patients);
    } catch (error) {
      onError();
    } finally {
      onFinal();
    }
  }

  static async fetchCorporateStaffs(
    corporateId: string,
    onSuccess: (staffs: User[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(
        ApiRoutes.CORPORATE_STAFFS.replace(":corporateId", corporateId)
      );
      const staffs = deserialize(User, data["users"] as User[]);
      onSuccess(staffs);
    } catch (error) {
      onError();
    } finally {
      onFinal();
    }
  }
  static async fetchCorporatePractices(
    corporateId: string,
    onSuccess: (practices: Practice[]) => void,
    onError: Function,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(
        ApiRoutes.CORPORATE_PRACTICES.replace(":corporateId", corporateId)
      );
      const practices = deserialize(Practice, data["practices"] as Practice[]);
      onSuccess(practices);
    } catch (error) {
      onError();
    } finally {
      onFinal();
    }
  }
}
