import React from "react";
import "./appHeader.scss";
import logo from "../../../assets/images/logoHorizontalBlack.png";
import { MenuOutlined, BellOutlined, LogoutOutlined } from "@ant-design/icons";
import { Row, Col, Button } from "antd";
import { useSelector } from "react-redux";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";

interface AppHeaderProps extends AuthReducerProps {}

function AppHeader({ authenticated }: AppHeaderProps) {
  const pageTitle = useSelector((state: any) => state.meta.pageTitle);

  const handleLogout = () => {
    localStorage.clear();
    window.location.replace(process.env.REACT_APP_REDIRECT_UN_AUTHORIZED ?? "");
  }

  return authenticated ? (
    <div className="app-header">
      <Row gutter={[0, 0]} align="middle">
        <Col span={3} className="app-header__logo-wrapper">
          <img src={logo} alt="Aligner 4D logo" className="app-header__logo" />
          <MenuOutlined className="app-header__menu" />
        </Col>
        <Col span={3} className="app-header__title">
          {pageTitle}
        </Col>

        <Col span={18} className="text-right">
          <Button className="mr-2 cursor-pointer" type="link" onClick={handleLogout}>
            <LogoutOutlined className="mr-2" />
            Logout
          </Button>
          <Button
            className="secondary-btn app-header__notification-btn"
            icon={<BellOutlined />}
          >
            <span className="app-header__notification-count">1</span>
          </Button>
        </Col>
      </Row>
    </div>
  ) : null;
}

export default AuthContainer(AppHeader);
