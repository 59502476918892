import { combineReducers } from "redux";
import authReducer from "./authReducer";
import metaReducer from "./metaReducer";

const RootReducer = combineReducers({
    auth: authReducer,
    meta: metaReducer
});

export default RootReducer;
