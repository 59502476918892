import React, { FC, useEffect, useState } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import requiredAuth from "../shared/components/HOC/requireAuth";
import { AppRoutes } from "./routeConstants/appRoutes";
import AppHeader from "../shared/components/AppHeader";
import AppSidebar from "../shared/components/AppSidebar";
import Payments from "../views/Payments";
import SubscriptionList from "../views/Subscriptions/SubscriptionList";
import Support from "../views/Support";
import Settings from "../views/Settings";
import Dashboard from "../views/Dashboard";
import PendingCorporateDetail from "../views/Dashboard/PendingCorporateDetail";
import CorporatesWrapper from "../views/Corporates/CorporatesWrapper";
import XDAnalytics from "../views/XDAnalytics";
import { useDispatch } from "react-redux";
import { SET_PAGE_TITLE } from "../store/definitions/metaConstants";
import AuthWrapper from "../views/Auth/AuthWrapper";
import DentalObjectList from "../views/Core3D/DentalObjectList";
import DentalObjectForm from "../views/Core3D/DentalObjectForm";
import DentalObjectDetail from "../views/Core3D/DentalObjectDetail";
import ObjectLinkForm from "../views/Core3D/ObjectLinkForm";
import AuthService from "../services/Auth/auth.service";
import AuthContainer from "../store/container/AuthContainer";
import { AuthReducerProps } from "../store/reducers/authReducer";
import { ResponsibilityTypes } from "../enums/responsibility.enum";
import CorporateDetails from "../views/Corporates/CorporateDetails";

export const appHistory = createBrowserHistory();

interface AppRouterProps extends AuthReducerProps {}

const AppRouter: FC<AppRouterProps> = (props) => {
  const { authenticated, setUser } = props;

  const dispatch = useDispatch();

  const pathname = appHistory.location.pathname;

  const [isDarkMode, setDarkMode] = useState(false);

  const isAuthenticated = (
    component: any,
    permission?: ResponsibilityTypes
  ) => {
    return requiredAuth(component, permission);
  };

  const routes = [
    {
      exact: true,
      path: AppRoutes.AUTH,
      component: AuthWrapper,
      title: "Auth",
    },
    {
      exact: true,
      path: AppRoutes.DASHBOARD,
      component: isAuthenticated(
        Dashboard,
        ResponsibilityTypes.CORPORATE_APPROVAL_VIEW
      ),
      title: "Dashboard",
    },
    {
      exact: true,
      path: AppRoutes.CORPORATES,
      component: isAuthenticated(
        CorporatesWrapper,
        ResponsibilityTypes.CORPORATE_VIEW
      ),
      title: "Corporates",
    },
    {
      exact: true,
      path: AppRoutes.PENDING_CORPORATE_DETAIL,
      component: isAuthenticated(PendingCorporateDetail),
      title: "Corporates",
    },
    {
      exact: true,
      path: AppRoutes.CORPORATE_DETAIL,
      component: isAuthenticated(CorporateDetails),
      title: "Corporates",
    },
    {
      exact: true,
      path: AppRoutes.SUBSCRIPTIONS,
      component: isAuthenticated(SubscriptionList),
      title: "Subscriptions",
    },
    {
      exact: true,
      path: AppRoutes.PAYMENTS,
      component: isAuthenticated(Payments),
      title: "Payments",
    },
    {
      exact: true,
      path: AppRoutes.XD_ANALYTICS,
      component: isAuthenticated(XDAnalytics),
      title: "Analytics",
    },
    {
      exact: true,
      path: AppRoutes.CORE_3D,
      component: isAuthenticated(
        DentalObjectList,
        ResponsibilityTypes.DENTAL_OBJECT_VIEW
      ),
      title: "3D Core",
    },
    {
      exact: true,
      path: AppRoutes.ADD_OBJECT_3D,
      component: isAuthenticated(DentalObjectForm),
      title: "3D Core",
    },
    {
      exact: true,
      path: AppRoutes.EDIT_OBJECT_3D,
      component: isAuthenticated(DentalObjectForm),
      title: "3D Core",
    },
    {
      exact: true,
      path: AppRoutes.CLONE_OBJECT_3D,
      component: isAuthenticated(DentalObjectForm),
      title: "3D Core",
    },
    {
      exact: true,
      path: AppRoutes.DENTAL_OBJECT_DETAIL,
      component: isAuthenticated(
        DentalObjectDetail,
        ResponsibilityTypes.DENTAL_OBJECT_VIEW
      ),
      title: "3D Core",
    },
    {
      exact: true,
      path: AppRoutes.ADD_OBJECT_LINK,
      component: isAuthenticated(ObjectLinkForm),
      title: "3D Core",
    },
    {
      exact: true,
      path: AppRoutes.SUPPORT,
      component: isAuthenticated(Support),
      title: "Support",
    },
    {
      exact: false,
      path: AppRoutes.SETTINGS,
      component: isAuthenticated(Settings),
      title: "Settings",
    },
  ];

  const object3DRoutes = [AppRoutes.OBJECT_3D, AppRoutes.OBJECT_LINKS];

  useEffect(() => {
    const routeIndex = routes.findIndex((route) => route.path === pathname);
    if (routeIndex >= 0) {
      dispatch({
        type: SET_PAGE_TITLE,
        payload: { pageTitle: routes[routeIndex].title },
      });
    }
    if (object3DRoutes.some((route) => pathname.includes(route))) {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
    appHistory.listen((location, action) => {
      const routeIndex = routes.findIndex(
        (route) => route.path === location.pathname
      );
      if (routeIndex >= 0) {
        dispatch({
          type: SET_PAGE_TITLE,
          payload: { pageTitle: routes[routeIndex].title },
        });
      }
      if (object3DRoutes.some((route) => location.pathname.includes(route))) {
        setDarkMode(true);
      } else {
        setDarkMode(false);
      }
    });
  }, [appHistory.location.pathname]);

  useEffect(() => {
    if (authenticated || localStorage.getItem("token")) {
      AuthService.getAdminDetail((admin) => {
        setUser(admin);
      });
    }
  }, [authenticated]);

  return (
    <div className={isDarkMode ? "theme--dark" : "theme--default"}>
      <div className="a4d-router-wrapper">
        <Router history={appHistory}>
          <AppHeader />
          <AppSidebar />
          <div className="app-wrapper">
            <Switch>
              <Redirect
                exact
                strict
                from={AppRoutes.HOME}
                to={AppRoutes.DASHBOARD}
              />
              {routes.map((route, index) => {
                return (
                  <Route key={index} {...route} component={route.component} />
                );
              })}
              <Route
                path="*"
                render={() => {
                  window.location.href = AppRoutes.LOGIN;
                  return null;
                }}
              />
            </Switch>
          </div>
        </Router>
      </div>
    </div>
  );
};

export default AuthContainer(AppRouter);
