import { Button, Col, Row } from "antd";
import { Form, Formik } from "formik";
import React, { FC } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Country } from "../../../../models/Country/country.model";
import { User } from "../../../../models/User/user.model";
import { IDropdownOptions } from "../../../../services/Meta/meta.service";
import { RoleService } from "../../../../services/Role/role.service";
import UserService from "../../../../services/User/user.service";
import UserMetaService from "../../../../services/UserMeta/userMeta.service";
import DatePickerField from "../../../../shared/components/DatePickerField";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import { getDropdownOptions } from "../../../../shared/utils/optionsUtils";
import "./userForm.scss";
import { userFormValidation } from "./validation";

const genderOptions = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Others",
    value: "Others",
  },
];

interface UserFormProps {
  formSuccessHandler: (user: User, isEdit?: boolean) => void;
  userValues: boolean | User;
  onCloseHandler: () => void;
}

const UserForm: FC<UserFormProps> = (props) => {
  const { userValues, formSuccessHandler, onCloseHandler } = props;

  const [countries, setCountries] = useState<IDropdownOptions[]>([]);

  const [provinces, setProvinces] = useState<IDropdownOptions[]>([]);

  const [cities, setCities] = useState<IDropdownOptions[]>([]);

  const [roles, setRoles] = useState<IDropdownOptions[]>([]);

  useEffect(() => {
    UserMetaService.getService(
      (countries) => {
        setCountries(getDropdownOptions(countries, "name", "id"));
      },
      () => {}
    );

    RoleService.getRoles(
      (roles) => {
        setRoles(getDropdownOptions(roles, "name", "id"));
      },
      () => {}
    );

    if (typeof userValues === "object" && userValues?.countryId) {
      getProvinces(userValues.countryId);
      if (userValues?.provinceId) {
        getCities(userValues.countryId, userValues.provinceId);
      }
    }
  }, []);

  const getProvinces = (countryId: string) => {
    UserMetaService.getProvinces(
      countryId,
      (provinces) => {
        setProvinces(getDropdownOptions(provinces, "name", "id"));
      },
      () => {}
    );
  };

  const getCities = (countryId: string, provinceId: string) => {
    UserMetaService.getCities(
      countryId,
      provinceId,
      (cities) => {
        setCities(getDropdownOptions(cities, "name", "id"));
      },
      () => {}
    );
  };

  const handleSubmit = (values: User) => {
    const user = Object.assign(new User(), values);
    const isEdit = typeof userValues === "object";
    UserService[isEdit ? "updateUser" : "createUser"](
      user,
      (user) => {
        formSuccessHandler(user, isEdit);
      },
      () => {}
    );
  };

  const formikProps = {
    initialValues: typeof userValues === "boolean" ? new User() : userValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: userFormValidation,
  };

  return (
    <div className="user-form">
      <Formik {...formikProps}>
        {({ isValid, dirty, values, setFieldValue }) => (
          <Form>
            <div className="white-wrapper">
              <Row gutter={[15, 0]}>
                <Col span={12}>
                  <InputField title="First Name" type="text" name="firstName" />
                </Col>
                <Col span={12}>
                  <InputField title="Last Name" type="text" name="lastName" />
                </Col>
              </Row>
              <Row gutter={[15, 0]}>
                <Col span={12}>
                  <DropdownField
                    name="gender"
                    title="Gender"
                    options={genderOptions}
                    defaultValue={values.gender}
                    onChange={(value) => {
                      setFieldValue("gender", value);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <DatePickerField
                    title="DOB"
                    name="dob"
                    defaultValue={values.dob}
                    setFieldValue={setFieldValue}
                  />
                </Col>
              </Row>
              <Row gutter={[15, 0]}>
                <Col span={12}>
                  <InputField title="Email" type="text" name="email" />
                </Col>
                <Col span={12}>
                  <InputField title="Mobile" type="text" name="mobileNumber" />
                </Col>
              </Row>
              <Row gutter={[15, 0]}>
                <Col span={12}>
                  <DropdownField
                    name="countryId"
                    title="Country"
                    defaultValue={values.countryId}
                    options={countries}
                    onChange={(value) => {
                      setFieldValue("countryId", value);
                      getProvinces(value);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <DropdownField
                    name="provinceId"
                    title="State/Province"
                    options={provinces}
                    defaultValue={values.provinceId}
                    onChange={(value) => {
                      setFieldValue("provinceId", value);
                      getCities(values?.countryId?.toString() ?? "", value);
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={[15, 0]}>
                <Col span={12}>
                  <DropdownField
                    name="cityId"
                    title="City"
                    defaultValue={values.cityId}
                    options={cities}
                    onChange={(value) => {
                      setFieldValue("cityId", value);
                    }}
                  />
                </Col>
                <Col span={12}>
                  <InputField title="Street" type="text" name="street" />
                </Col>
              </Row>
              <Row gutter={[15, 0]}>
                <Col span={12}>
                  <InputField title="Building" type="text" name="building" />
                </Col>
                <Col span={12}>
                  <InputField title="Postal code" type="text" name="zipcode" />
                </Col>
              </Row>
              <Row gutter={[15, 0]}>
                <Col span={12}>
                  <DropdownField
                    name="roleId"
                    title="Role"
                    defaultValue={values.role?.id}
                    options={roles}
                    onChange={(value) => {
                      setFieldValue("roleId", value);
                    }}
                  />
                </Col>
              </Row>
            </div>
            <Row justify="end" gutter={[10, 0]} className="mt-4">
              <Col>
                <Button type="default" onClick={onCloseHandler}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={!dirty || !isValid}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserForm;
