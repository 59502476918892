import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import CorporateList from "../../shared/components/CorporateList";
import { CorporateStatusEnum } from "../../enums/corporateStatus.enum";
import Stats from "../../shared/components/Stats";

import MetricsService from "../../services/Metrics/metrics.service";
import { Metrics } from "../../models/Metrics/metrics.model";
import AppLoader from "../../shared/components/AppLoader";

interface DashboardProps {}

function Dashboard(props: DashboardProps) {
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState<Metrics>();
  const handleFetchCorporateMetrics = () => {
    setLoading(true);
    MetricsService.fetchCorporateMetrics(
      "corporates",
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    handleFetchCorporateMetrics();
  }, []);
  const appointmentStatsData = [
    {
      title: "Approved Corporate",
      value: metrics?.approvedCorporates ?? 0,
    },
    {
      title: "Pending Corporate",
      value: metrics?.pendingCorporates ?? 0,
    },
    {
      title: "Rejected Corporate",
      value: metrics?.rejectedCorporates ?? 0,
    },
  ];
  return (
    <div className="dashboard">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <>
          <Stats statsData={appointmentStatsData} />

          <h5 className="text-secondary mt-5">Pending Corporates Approval</h5>
          <CorporateList status={CorporateStatusEnum.PENDING} />
        </>
      )}
    </div>
  );
}

export default Dashboard;
