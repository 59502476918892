import { Table, Switch, Button, Drawer } from "antd";
import React, { FC, useState } from "react";
import { useEffect } from "react";
import { User } from "../../../models/User/user.model";
import UserService from "../../../services/User/user.service";
import { PlusOutlined } from "@ant-design/icons";
import "./users.scss";
import UserForm from "./UserForm";
import { Country } from "../../../models/Country/country.model";
import { useCallback } from "react";
import { Role } from "../../../models/Role/role.model";
import Stats from "../../../shared/components/Stats";
import { Metrics } from "../../../models/Metrics/metrics.model";
import MetricsService from "../../../services/Metrics/metrics.service";
import { useParams } from "react-router-dom";

interface UsersProps {}

const Users: FC<UsersProps> = (props) => {
  const {} = props;

  const [users, setUsers] = useState<User[]>([]);

  const params = useParams<{ corporateId: string }>();
  const [userForm, setUserForm] = useState<boolean | User>(false);
  const [metrics, setMetrics] = useState<Metrics>();

  useEffect(() => {
    UserService.fetchUsers(
      (users) => {
        setUsers(users);
      },
      () => {}
    );
    MetricsService.fetchCorporateMetrics(
      "admins",
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {}
    );
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_: string, user: User) => (
        <span>
          {user.firstName} {user.lastName}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role: Role) => role?.name || "",
    },
    {
      title: "",
      dataIndex: "userRole",
      key: "userRole",
      render: (_: string, user: User) => {
        const handleUpdateUser = () => {
          setUserForm(user);
        };
        return (
          <span>
            <i className="icon-edit" onClick={handleUpdateUser} />
            <Switch className="ml-5" defaultChecked />
          </span>
        );
      },
    },
  ];

  const handleCloseUserForm = () => {
    setUserForm(false);
  };

  const handleOpen = () => {
    setUserForm(true);
  };

  const handleFormSuccess = useCallback((user, isEdit) => {
    if (isEdit) {
      const index = users.findIndex(
        (existingUser) => existingUser.id === user.id
      );
      const updateUsers = [...users];
      updateUsers.splice(index, 1, user);
      setUsers([...updateUsers]);
    } else {
      setUsers((users) => [user, ...users]);
    }
    setUserForm(false);
  }, []);
  const statsData = [
    {
      title: "Total Users",
      value: metrics?.totalUsers ?? 0,
    },
    {
      title: "Active Users",
      value: metrics?.activeUsers ?? 0,
    },
    {
      title: "Inactive Users",
      value: metrics?.inactiveUsers ?? 0,
    },
  ];

  return (
    <div className="users">
      <div className="header">
        <p className="text-secondary">All Users</p>

        <Button type="primary" onClick={handleOpen}>
          <PlusOutlined /> Add Role
        </Button>
      </div>
      <Stats statsData={statsData} />
      <Table dataSource={users} columns={columns} />
      <Drawer
        title="Add User"
        visible={userForm ? true : false}
        width={"45vw"}
        destroyOnClose
        onClose={handleCloseUserForm}
      >
        <UserForm
          userValues={userForm}
          formSuccessHandler={handleFormSuccess}
          onCloseHandler={handleCloseUserForm}
        />
      </Drawer>
    </div>
  );
};

export default Users;
