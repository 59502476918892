import { deserialize, serialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { Role } from "../../models/Role/role.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";

export class RoleService {
  static async getRoles(
    onSuccess: (roles: Role[]) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.ROLES);
      const roles = deserialize(Role, data?.roles as any[]);
      onSuccess(roles);
    } catch (error) {
      console.log(error.message);
    } finally {
      onFinal();
    }
  }

  static async createRole(
    role: Role,
    onSuccess: (role: Role) => void,
    onFinal: () => void
  ) {
    try {
      const roleJson = {
        role: serialize(role),
      };
      const { data } = await axiosInstance.post(ApiRoutes.ROLES, roleJson);
      const newRole = deserialize(Role, data?.role);
      onSuccess(newRole);
    } catch (error) {
      console.log(error.message);
    } finally {
      onFinal();
    }
  }

  static async updateRole(
    role: Role,
    onSuccess: (role: Role) => void,
    onFinal: () => void
  ) {
    try {
      const roleJson = {
        role: serialize(role),
      };
      const URL = ApiRoutes.ROLES + "/" + role.id;
      const { data } = await axiosInstance.put(URL, roleJson);
      const newRole = deserialize(Role, data?.role);
      onSuccess(newRole);
    } catch (error) {
      console.log(error.message);
    } finally {
      onFinal();
    }
  }
}
