import { Permission } from "../Permission/permission.model";

import { serializable, alias, object, list, primitive } from "serializr";

export class Role {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("category", primitive()))
  category?: string;

  @serializable(alias("is_default", primitive()))
  isDefault?: string;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("permissions", list(primitive())))
  permissions: string[] = [];
}
