export const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  TOKEN: "/token",
  CORPORATES: "/api/v1/admin/corporates",
  ADMIN_DETAILS: "/api/v1/admin/user_details",
  CORPORATE_DETAIL: "/api/v1/admin/corporates/:corporateId/profiles",
  APPROVE_CORPORATE: "/api/v1/admin/corporates/:corporateId/approve",
  INVITES: "/api/v1/admin/invites",
  REJECT_CORPORATE: "/api/v1/admin/corporates/:corporateId/reject",
  DENTAL_OBJECTS: "/api/v1/admin/dental_objects",
  OBJECT_LINKS: "/api/v1/admin/object_links",
  MANUFACTURERS: "/api/v1/admin/manufacturers",
  OBJECT_CATEGORIES: "/api/v1/admin/object_categories",
  OBJECT_MODELS: "/api/v1/admin/object_models",
  COUNTRIES: "/api/v1/countries",
  PROVINCES: "/api/v1/countries/:countryId/provinces",
  CITIES: "/api/v1/countries/:countryId/provinces/:provinceId/cities",
  ROLES: "/api/v1/admin/roles",
  USERS: "api/v1/admin/admins",
  SUBSCRIPTION: "/api/v1/admin/subscriptions",
  ISSUES: "/api/v1/admin/issues",
  ISSUE_COMMENTS: `/api/v1/admin/issues/:issueId/comments`,
  CORPORATE_PATIENTS: "/api/v1/admin/corporates/:corporateId/patients",
  CORPORATE_STAFFS: "/api/v1/admin/corporates/:corporateId/staffs",
  CORPORATE_PRACTICES: "/api/v1/admin/corporates/:corporateId/practices",
  ATTACHMENTS: "/api/v1/admin/issues/:issueId/attachments",
  GET_CORPORATE_SUBSCRIPTION_FREQUENCY:
    "api/v1/admin/corporates/:corporateId/get_subscription_frequency",
  LIST_SUBSCRIPTION_PAYMENTS: "/api/v1/admin/subscription_payments",
  LIST_PAYMENT_INTENTS: "/api/v1/admin/payment_intents",
  LIST_PAYMENT_TRANSFERS: "/api/v1/admin/payment_transfers",
  LIST_OFFLINE_PAYMENTS: "/api/v1/admin/offline_payments",

  METRICS: "/api/v1/admin/corporates/:corporateId/:metricsType/metrics",

  CORPORATE_METRICS: "/api/v1/admin/:metricsType/metrics",
};

export default ApiRoutes;
