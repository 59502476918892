import { ObjectLocator } from "../ObjectLocator/objectLocator.model";

import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
} from "serializr";

export class DentalObject {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("no_of_links", primitive()))
  noOfLinks?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("friendly_name", primitive()))
  friendlyName?: string;

  @serializable(alias("object_model_id", primitive()))
  objectModelId?: number;

  @serializable(alias("manufacturer_id", primitive()))
  manufacturerId?: number;

  @serializable(alias("manufacturer_name", primitive()))
  manufacturerName?: string;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("color", primitive()))
  color?: string = "#FFFFFF";

  @serializable(
    alias(
      "attachment",
      custom(
        (file) => file,
        (json) => {}
      )
    )
  )
  attachment?: File;

  @serializable(alias("attachment_url", primitive()))
  attachmentUrl?: string;

  @serializable(alias("object_model_name", primitive()))
  objectModelName?: string;

  @serializable(alias("object_category_id", primitive()))
  objectCategoryId?: number;

  @serializable(alias("object_category_name", primitive()))
  objectCategoryName?: string;

  @serializable(alias("object_type", primitive()))
  objectType?: string;

  @serializable(alias("object_locators", list(object(ObjectLocator))))
  objectLocators: ObjectLocator[] = [];
}
