import { findAllByTitle } from "@testing-library/react";
import { Button, Col, Row, Tabs } from "antd";
import React, { FC } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Corporate } from "../../../models/Corporate/corporate.model";
import CorporateService from "../../../services/Corporate/corporate.service";
import "./corporateDetails.scss";
import CorporateInformation from "./CorporateInformation";
import Patients from "./Patients";
import Practices from "./Practices";
import Staffs from "./Staffs";
import Subscription from "./Subscription";

const { TabPane } = Tabs;

interface CorporateDetailsProps {}

const corporateTabs = [
  {
    title: "Corporate Information",
    component: <CorporateInformation />,
  },
  {
    title: "Practices",
    component: <Practices />,
  },
  {
    title: "Staffs",
    component: <Staffs />,
  },
  {
    title: "Subscription",
    component: <Subscription />,
  },
  {
    title: "Patients",
    component: <Patients />,
  },
];

const CorporateDetails: FC<CorporateDetailsProps> = (props) => {
  const {} = props;

  return (
    <div className="corporate-details">
      <Tabs size="small" defaultActiveKey={"0"}>
        {corporateTabs.map(({ title, component }, index) => (
          <TabPane tab={title} key={index}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default CorporateDetails;
