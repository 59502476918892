import { notification } from 'antd';

interface INotification {
    message: string;
    description?: string;
    type: string
}

type notificationType = "success" | "error" | "warning" | "info" | "open";

const Notification = ({ message, description, type }: INotification) => {
    return notification[type as notificationType]({
        message,
        description
    })
};

export default Notification; 
