import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { DentalObject } from "../../models/DentalObject/dentalObject.model";
import { deserialize, object, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import {
  convertJSONToFormData,
  sanitizeJSON,
} from "../../shared/utils/dataFormatConverter";
import { FormActionEnum } from "../../enums/formAction.enum";

export default class DentalObjectService {
  static fetchDentalObjects(
    objectType: string = "",
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.DENTAL_OBJECTS, {
        params: {
          object_types: [objectType],
        },
      })
      .then((response) => {
        const dentalObjects = deserialize(
          DentalObject,
          response.data["dental_objects"]
        );
        onSuccess(dentalObjects);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showDentalObject(
    dentalObjectId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.DENTAL_OBJECTS + "/" + dentalObjectId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const dentalObject = deserialize(
          DentalObject,
          response.data["dental_object"]
        );
        onSuccess(dentalObject);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createDentalObject(
    dentalObject: DentalObject,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const dentalObjectJSON: any = {
      dental_object: serialize(dentalObject),
    };
    dentalObjectJSON["attachment"] =
      dentalObjectJSON["dental_object"]["attachment"];
    delete dentalObjectJSON["dental_object"]["attachment"];
    const dentalObjectForm = convertJSONToFormData(dentalObjectJSON);
    axiosInstance
      .post(ApiRoutes.DENTAL_OBJECTS, dentalObjectForm)
      .then((response) => {
        Notification({
          message: "Object created!",
          type: NotificationTypes.SUCCESS,
        });
        const dentalObject = deserialize(
          DentalObject,
          response.data["dental_object"]
        );
        onSuccess(dentalObject);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateDentalObject(
    dentalObject: DentalObject,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.DENTAL_OBJECTS + "/" + dentalObject.id;
    const dentalObjectJSON: any = {
      dental_object: serialize(dentalObject),
    };
    dentalObjectJSON["attachment"] =
      dentalObjectJSON["dental_object"]["attachment"];
    delete dentalObjectJSON["dental_object"]["attachment"];
    const dentalObjectForm = convertJSONToFormData(dentalObjectJSON);
    axiosInstance
      .put(API_URL, dentalObjectForm)
      .then((response) => {
        Notification({
          message: "Object updated!",
          type: NotificationTypes.SUCCESS,
        });
        const dentalObject = deserialize(
          DentalObject,
          response.data["dental_object"]
        );
        onSuccess(dentalObject);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static cloneDentalObject(
    dentalObject: DentalObject,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.DENTAL_OBJECTS + "/" + dentalObject.id + "/clone";
    const dentalObjectJSON: any = {
      dental_object: serialize(dentalObject),
    };
    dentalObjectJSON["attachment"] =
      dentalObjectJSON["dental_object"]["attachment"];
    delete dentalObjectJSON["dental_object"]["attachment"];
    const dentalObjectForm = convertJSONToFormData(dentalObjectJSON);
    axiosInstance
      .post(API_URL, dentalObjectForm)
      .then((response) => {
        Notification({
          message: "Object cloned!",
          type: NotificationTypes.SUCCESS,
        });
        const dentalObject = deserialize(
          DentalObject,
          response.data["dental_object"]
        );
        onSuccess(dentalObject);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteDentalObject(
    dentalObjectId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.DENTAL_OBJECTS + "/" + dentalObjectId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
