import React, { useEffect, useState } from "react";
import { Row, Col, Button, Drawer } from "antd";
import "./dentalObjectDetail.scss";
import { DentalObject } from "../../../models/DentalObject/dentalObject.model";
import DentalObjectService from "../../../services/DentalObject/dentalObject.service";
import { useParams, Link, generatePath } from "react-router-dom";
import DentalObjectPreview from "../DentalObjectPreview";
import { EditOutlined, CopyOutlined } from "@ant-design/icons";
import ObjectLinkList from "../ObjectLinkList";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import AppLoader from "../../../shared/components/AppLoader";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsibility.enum";

interface DentalObjectDetailProps extends AuthReducerProps {}

interface DentalObjectParamsProps {
  dentalObjectId?: string;
}

interface DentalObjectFieldProps {
  label: string;
  value?: string;
  isColor?: boolean;
}

function DentalObjectDetail({ userResponsibilities }: DentalObjectDetailProps) {
  const params: DentalObjectParamsProps = useParams();

  const [dentalObject, setDentalObject] = useState<DentalObject | null>();

  const [showLinks, setShowLinks] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleToggleObjectLinks = () => setShowLinks(!showLinks);

  const dentalObjectFields: DentalObjectFieldProps[] = [
    { label: "Model name", value: dentalObject?.name },
    { label: "Friendly name", value: dentalObject?.friendlyName },
    { label: "Type", value: dentalObject?.objectType },
    { label: "Category", value: dentalObject?.objectCategoryName },
    { label: "Model", value: dentalObject?.objectModelName },
    { label: "Manufacture", value: dentalObject?.manufacturerName },
    { label: "Description", value: dentalObject?.description },
    { label: "Color", value: dentalObject?.color, isColor: true },
  ];

  useEffect(() => {
    if (params.dentalObjectId) {
      setLoading(true);
      DentalObjectService.showDentalObject(
        Number(params.dentalObjectId),
        (dentalObject: DentalObject) => {
          setDentalObject(dentalObject);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, []);
  return (
    <div className="dental-object-detail">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <React.Fragment>
          <Row align="middle">
            <Col span={4}>
              Objects /{" "}
              <span className="text-secondary">{dentalObject?.name}</span>
            </Col>
            <Col span={20} className="text-right">
              {userResponsibilities.includes(ResponsibilityTypes.LINK_VIEW) && (
                <Button
                  className="secondary-btn"
                  onClick={handleToggleObjectLinks}
                >
                  Show Links
                </Button>
              )}
              {dentalObject && (
                <React.Fragment>
                  {userResponsibilities.includes(
                    ResponsibilityTypes.LINK_EDIT
                  ) && (
                    <Link
                      to={generatePath(AppRoutes.EDIT_OBJECT_3D, {
                        dentalObjectId: dentalObject?.id,
                      })}
                    >
                      <Button type="primary" className="ml-2">
                        <EditOutlined /> Edit Object
                      </Button>
                    </Link>
                  )}

                  {userResponsibilities.includes(
                    ResponsibilityTypes.LINK_CREATE
                  ) && (
                    <Link
                      to={generatePath(AppRoutes.CLONE_OBJECT_3D, {
                        dentalObjectId: dentalObject?.id,
                      })}
                    >
                      <Button type="primary" className="ml-2">
                        <CopyOutlined /> Clone Object
                      </Button>
                    </Link>
                  )}
                </React.Fragment>
              )}
            </Col>
          </Row>
          <div className="dental-object-detail__body">
            <Row gutter={[20, 0]}>
              <Col span={8}>
                {dentalObjectFields.map((dentalObjectField, i) => (
                  <div key={i} className="dental-object-detail__field">
                    <div className="dental-object-detail__label">
                      {dentalObjectField?.label}
                    </div>
                    <div className="dental-object-detail__value">
                      {dentalObjectField?.isColor ? (
                        <span
                          className="dental-object-detail__color"
                          style={{
                            backgroundColor: dentalObjectField?.value,
                          }}
                        />
                      ) : (
                        dentalObjectField?.value || "NA"
                      )}
                    </div>
                  </div>
                ))}
              </Col>
              <Col span={16}>
                <DentalObjectPreview objectUrl={dentalObject?.attachmentUrl} />
              </Col>
              <Drawer
                title={<h4>Object Links</h4>}
                placement="right"
                maskClosable={false}
                onClose={handleToggleObjectLinks}
                visible={showLinks}
                destroyOnClose={true}
                width="60%"
              >
                <ObjectLinkList dentalObjectId={dentalObject?.id} />
              </Drawer>
            </Row>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default AuthContainer(DentalObjectDetail);
