import axios from "axios";
import { store } from "../store";
import { REQUEST_LOGOUT } from "../store/definitions/authConstants";
import ApiRoutes from "../routes/routeConstants/apiRoutes";
import { AppRoutes } from "../routes/routeConstants/appRoutes";

export const getHeaders = (): any => {
  let headers, token;
  if (localStorage.getItem("token")) {
    token = localStorage.getItem("token");
  }
  headers = {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": "7fa032f72e00466ca4392db3435cd535",
    Authorization: `Bearer ${token}`,
  };
  return headers;
};

const axiosInstance = axios.create({
  baseURL: ApiRoutes.BASE_URL,
  timeout: 40000,
});

axiosInstance.interceptors.request.use(function (config) {
  config.headers = getHeaders();
  return config;
});

axiosInstance.interceptors.response.use(
  (response): any => {
    return {
      data: response.data,
      message: response.statusText,
      status: response.status,
    };
  },
  (error) => {
    const { response } = error;
    if (response && response.status === 401) {
      localStorage.clear();
      window.location.href = AppRoutes.LOGIN;
    }
    throw response && response.data ? response.data.message : "Network error";
  }
);

export default axiosInstance;
