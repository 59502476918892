import * as Yup from "yup";

export const validateString = (message?: string, required?: boolean) => {
  return required
    ? Yup.string()
        .required(message || "This field is required")
        .nullable()
    : Yup.string().nullable();
};

export const validateNumber = (message?: string, required?: boolean) => {
  return required
    ? Yup.number()
        .required(message || "This field is required")
        .typeError("Should be a number")
        .nullable()
    : Yup.string().nullable();
};
