import axiosInstance from "../../interceptor/axiosInstance";
import Subscription from "../../models/Subscription/subscription.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { Comment } from "../../models/Comment/comment.model";
import { deserialize, serialize } from "serializr";
import { Support } from "../../models/Support/support.model";
import { Attachment } from "../../models/Attachment/attachment.mode";

export default class SupportService {
  // List all issues
  static async fetchSubscription(
    onSuccess: (supports: Support[]) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.ISSUES);
      const supports = deserialize(Support, data["issues"] as Support[]);
      onSuccess(supports);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  // Create issues
  static async viewSubscription(
    supportId: number,
    onSuccess: (support: Support) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.ISSUES + "/" + supportId;
      const { data } = await axiosInstance.get(URL);
      const support = deserialize(Support, data["issue"]);
      onSuccess(support);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  // Create issues
  static async createSubscription(
    support: Support,
    onSuccess: (support: Support) => void,
    onFinal: () => void
  ) {
    try {
      const supportJSON = {
        issue: serialize(support),
      };
      const { data } = await axiosInstance.post(ApiRoutes.ISSUES, supportJSON);
      const createdIssue = deserialize(Support, data["issue"]);
      onSuccess(createdIssue);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  // update issues
  static async updateSubscription(
    subscription: Support,
    onSuccess: (subscription: Support) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.ISSUES + "/" + subscription?.id;

      const supportJSON = {
        issue: serialize(subscription),
      };
      const { data } = await axiosInstance.put(URL, supportJSON);
      const updatedIssue = deserialize(Support, data["issue"]);
      onSuccess(updatedIssue);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async fetchComments(
    issueId: number,
    onSuccess: (comments: Comment[]) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.ISSUE_COMMENTS.replace(
        ":issueId",
        issueId?.toString()
      );

      const { data } = await axiosInstance.get(URL);
      const comments = deserialize(
        Comment,
        data["issue_comments"] as Comment[]
      );
      onSuccess(comments);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async addComments(
    issueId: number,
    comment: Comment,
    onSuccess: (comment: Comment) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.ISSUE_COMMENTS.replace(
        ":issueId",
        issueId?.toString()
      );
      const CommentJSON = {
        issue_comment: serialize(comment),
      };
      const { data } = await axiosInstance.post(URL, CommentJSON);
      const addedComment = deserialize(
        Comment,
        data["issue_comment"] as Comment
      );
      onSuccess(addedComment);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async updateComments(
    issueId: number,
    comment: Comment,
    onSuccess: (comment: Comment) => void,
    onFinal: () => void
  ) {
    try {
      const URL =
        ApiRoutes.ISSUE_COMMENTS.replace(":issueId", issueId?.toString()) +
        "/" +
        comment?.id;
      const CommentJSON = {
        issue_comment: serialize(comment),
      };
      const { data } = await axiosInstance.put(URL, CommentJSON);
      const addedComment = deserialize(
        Comment,
        data["issue_comment"] as Comment
      );
      onSuccess(addedComment);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async deleteComments(
    issueId: number,
    commentId: number,
    onSuccess: (commentId: number) => void,
    onFinal: () => void
  ) {
    try {
      const URL =
        ApiRoutes.ISSUE_COMMENTS.replace(":issueId", issueId?.toString()) +
        "/" +
        commentId;
      await axiosInstance.delete(URL);
      onSuccess(commentId);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async viewAttachments(
    issueId: number,
    onSuccess: (attachments: Attachment[]) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.ATTACHMENTS.replace(
        ":issueId",
        issueId?.toString()
      );
      const { data } = await axiosInstance.get(URL);
      const attachments = deserialize(
        Attachment,
        data["issue_attachments"] as any[]
      );
      onSuccess(attachments);
    } catch (error) {
    } finally {
      onFinal();
    }
  }
}
