import { Table } from "antd";
import React, { FC } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Metrics } from "../../../models/Metrics/metrics.model";
import Subscription from "../../../models/Subscription/subscription.model";
import { SubscriptionPayment } from "../../../models/SubscriptionPayment/subscriptionPayment.model";
import PaymentService from "../../../services/Payment/payment.service";
import Stats from "../../../shared/components/Stats";
import "./subscriptionList.scss";

interface SubscriptionListProps {}

const subscriptions = Array(5).fill({
  name: "Kevin Patel",
  plan: "Silver Plan",
  frequency: "Monthly",
  paymentId: "KDI2399329324E",
  status: "Paid",
});

const columns = [
  {
    title: "Corporate name",
    dataIndex: "corporateName",
    key: "corporateName",
  },
  {
    title: "Subscription plan",
    dataIndex: "subscriptionName",
    key: "subscriptionName",
  },
  {
    title: "Payment frequency",
    dataIndex: "subscriptionInterval",
    key: "subscriptionInterval",
  },
  {
    title: "Payment ID",
    dataIndex: "stripePaymentIntentId",
    key: "stripePaymentIntentId",
  },
  {
    title: "Status",
    dataIndex: "stripePaymentIntentStatus",
    key: "stripePaymentIntentStatus",
    render: (status: string) => (
      <span
        className={`${
          status === "paid" || status === "open"
            ? "text-success"
            : "text-warning"
        }`}
      >
        {status}
      </span>
    ),
  },
];

const SubscriptionList: FC<SubscriptionListProps> = (props) => {
  const {} = props;
  const [metrics, setMetrics] = useState<Metrics>();

  const [subscriptions, setSubscriptions] = useState<SubscriptionPayment[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    PaymentService.getSubscriptionPayments(
      (subscriptions) => {
        setSubscriptions(subscriptions);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);
  const statsData = [
    {
      title: "Payment Due For Month",
      value: 0,
    },
    {
      title: "Amount Received",
      value: 0,
    },
    {
      title: "Pending/Failed Payment",
      value: 0,
    },
  ];

  return (
    <div className="subscription-list">
      <Stats statsData={statsData} />
      <Table
        /* dataSource={subscriptions} */ columns={columns}
        loading={loading}
      />
    </div>
  );
};

export default SubscriptionList;
