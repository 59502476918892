import { Col, Row } from "antd";
import React, { FC } from "react";
import { StatsTypeEnum } from "../../../enums/statsType.enum";
import { hexAToRGBA } from "../../utils/hexToRgb";
import { numberWithCommas } from "../../utils/numberWithCommas";
import "./stats.scss";

interface StatsData {
  title: string;
  value: number;
}
interface StatProps {
  statsData?: StatsData[];
  statsType?: StatsTypeEnum;
  handleVisibility?: () => void;
}
const colors = [
  { light: "#CED9FD", dark: "#3E4B78" },
  { light: "#F9CBA1", dark: "#AC682A" },
  { light: "#BDDBA6", dark: "#536B40" },
  { light: "#DBA6A6", dark: "#6B4040" },
];

const Stats: FC<StatProps> = (props) => {
  const { statsData, statsType, handleVisibility } = props;
  return (
    <Row className="stats__container mt-3" gutter={[15, 15]}>
      {statsData?.map((data, index) => (
        <Col span={statsType === StatsTypeEnum.PATIENTS ? 6 : 8}>
          <Row
            className="stat__container"
            style={{ backgroundColor: colors[index].light }}
          >
            <i
              className="icon-services float-icon"
              style={{
                color: hexAToRGBA(colors[index].dark, 0.1),
              }}
            />

            <Col span={8} className="stat-icon__wrapper">
              <i
                className="icon-services"
                style={{
                  backgroundColor: hexAToRGBA(colors[index].dark, 0.5),
                  color: colors[index].dark,
                }}
              />
            </Col>
            <Col span={16} className="stat-content__wrapper">
              <p>{data?.title}</p>
              <h2 className="stat-content__value">
                {numberWithCommas(Number(data?.value))}
              </h2>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default Stats;

/* import { Col, Row } from "antd";
import React, { FC } from "react";
import "./stats.scss";

interface StatsProps {}

const Stats: FC<StatsProps> = (props) => {
  const {} = props;

  return (
    <div className="corporates-wrapper__stats">
      <Row gutter={[20, 0]} className="corporates-wrapper__stats-row">
        <Col span={8} className="corporates-wrapper__stats-col">
          <div className="corporates-wrapper__stats-card corporates-wrapper__staffs">
            <i className="icon-total-value1 background-icon" />
            <Row align="middle">
              <Col span={8}>
                <i className="icon-total-value1" />
              </Col>
              <Col span={16}>
                <div>Total staffs</div>
                <h2>34</h2>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={8} className="corporates-wrapper__stats-col">
          <div className="corporates-wrapper__stats-card corporates-wrapper__doctors">
            <i className="icon-total-value2 background-icon" />
            <Row align="middle">
              <Col span={8}>
                <i className="icon-total-value2" />
              </Col>
              <Col span={16}>
                <div>Total Doctors</div>
                <h2>34</h2>
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={8} className="corporates-wrapper__stats-col">
          <div className="corporates-wrapper__stats-card corporates-wrapper__assistants">
            <i className="icon-total-value3 background-icon" />
            <Row align="middle">
              <Col span={8}>
                <i className="icon-total-value3" />
              </Col>
              <Col span={16}>
                <div>Total Assitants</div>
                <h2>34</h2>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Stats;
 */
