import {serializable, alias, primitive} from 'serializr';

export class Practice {

    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('contact_person', primitive()))
    contactPerson?: string;

    @serializable(alias('country_code', primitive()))
    countryCode?: string;

    @serializable(alias('mobile_number', primitive()))
    mobileNumber?: string;

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('time_zone', primitive()))
    timeZone?: string;

    @serializable(alias('no_of_Chairs', primitive()))
    noOfChairs?: string;

    @serializable(alias('no_of_rooms', primitive()))
    noOfRooms?: string;

    @serializable(alias('no_of_labs', primitive()))
    noOfLabs?: string;

    @serializable(alias('location', primitive()))
    location?: string;
}
