import { CreateReducer } from "../../shared/utils/createReducer";
import {SET_PAGE_TITLE} from "../definitions/metaConstants";

interface IMetaState {
    pageTitle: string;
}

const initState: IMetaState = {
    pageTitle: 'Dashboard'
};

const authReducer = CreateReducer(initState, {
    [SET_PAGE_TITLE](state: any, action: any) {
        const { pageTitle } = action.payload;
        return {
            ...state,
            pageTitle
        };
    }
});

export default authReducer;



