import { ReactNode } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Manufacturer } from "../../models/Manufacturer/manufacturer.model";
import { ObjectCategory } from "../../models/ObjectCategory/objectCategory.model";
import { ObjectModel } from "../../models/ObjectModel/objectModel.model";
export interface IDropdownOptions {
  label?: string;
  value?: string | number;
  optionalLabel?: string | ReactNode;
}

export class MetaService {
  static fetchManufacturers(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.MANUFACTURERS)
      .then((response) => {
        const manufacturers = deserialize(
          Manufacturer,
          response.data["manufacturers"]
        );
        onSuccess(manufacturers);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchObjectCategories(
    objectType: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.OBJECT_CATEGORIES, {
        params: {
          object_type: objectType,
        },
      })
      .then((response) => {
        const objectCategories = deserialize(
          ObjectCategory,
          response.data["object_categories"]
        );
        onSuccess(objectCategories);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchObjectModels(
    objectCategoryId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.OBJECT_MODELS, {
        params: {
          object_category_id: objectCategoryId,
        },
      })
      .then((response) => {
        const objectModels = deserialize(
          ObjectModel,
          response.data["object_models"]
        );
        onSuccess(objectModels);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
