import { Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import { PaymentIntent } from "../../../models/PaymentIntent/paymentIntent.model";
import PaymentService from "../../../services/Payment/payment.service";
import Stats from "../../../shared/components/Stats";
import moment from "moment";
import "./onlineTransaction.scss";

interface OnlineTransactionProps {}

const columns = [
  {
    title: "Patient ID",
    dataIndex: "contractPatientId",
    key: "contractPatientId",
  },
  {
    title: "Contract ID",
    dataIndex: "contractId",
    key: "contractId",
  },
  {
    title: "Transaction ID",
    dataIndex: "stripePaymentIntentId",
    key: "stripePaymentIntentId",
  },
  {
    title: "Corporate Name",
    dataIndex: "corporateName",
    key: "corporateName",
  },
  {
    title: "Practice Name",
    dataIndex: "practiceName",
    key: "practiceName",
  },
  {
    title: "Payment Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date: string) => <span>{moment(date).format("DD/MM/YYYY")}</span>,
  },
  {
    title: "Payment Method",
    dataIndex: "paymentMethod",
    key: "paymentMethod",
    render: () => "Stripe",
  },
  {
    title: "Status",
    dataIndex: "stripePaymentIntentStatus",
    key: "stripePaymentIntentStatus",
    render: (status: string) => (
      <span
        className={`${
          status === "paid" || status === "open"
            ? "text-success"
            : "text-warning"
        }`}
      >
        {status}
      </span>
    ),
  },
];

const OnlineTransaction: FC<OnlineTransactionProps> = (props) => {
  const {} = props;

  const [paymentIntents, setPaymentIntents] = useState<PaymentIntent[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    PaymentService.getPaymentIntents(
      (paymentIntents) => {
        setPaymentIntents(paymentIntents);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);
  const statsData = [
    {
      title: "Payment Due For Month",
      value: 0,
    },
    {
      title: "Amount Received",
      value: 0,
    },
    {
      title: "Pending/Failed Payment",
      value: 0,
    },
  ];

  return (
    <div className="online-transaction">
      <Stats statsData={statsData} />
      <Table
        /* dataSource={paymentIntents} */ columns={columns}
        loading={loading}
      />
    </div>
  );
};

export default OnlineTransaction;
