import { Button } from "antd";
import { Form, Formik } from "formik";
import React, { FC } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Support } from "../../../models/Support/support.model";
import { User } from "../../../models/User/user.model";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import SupportService from "../../../services/Support/support.service";
import UserService from "../../../services/User/user.service";
import DropdownField from "../../../shared/components/DropdownField";
import { getDropdownOptions } from "../../../shared/utils/optionsUtils";

interface AssignProps {
  support: Support;
  onCloseHandler: (support: Support) => void;
}

const Assign: FC<AssignProps> = (props) => {
  const { support, onCloseHandler } = props;

  const [users, setUsers] = useState<IDropdownOptions[]>([]);

  const [loading, setLoading] = useState(false);

  const [canAssign, setCanAssign] = useState(false);

  useEffect(() => {
    if (canAssign) {
      setLoading(true);
      UserService.fetchUsers(
        (users) => {
          setUsers(
            users?.map(({ id, firstName, lastName }) => ({
              label: firstName + " " + lastName,
              value: id,
            }))
          );
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [canAssign]);

  const handleSubmit = (values: Support) => {};

  const handleAssignTo = (values: Support) => {
    const support = Object.assign(new Support(), values);
    setLoading(true);
    SupportService.updateSubscription(
      support,
      (support) => {
        onCloseHandler(support);
      },
      () => {
        setLoading(false);
      }
    );
  };
  if (canAssign) {
    return (
      <Formik initialValues={support} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <DropdownField
              value={values.assignedToAdminId}
              name="assignedToAdminId"
              options={users}
              onChange={(id) => {
                handleAssignTo({ ...values, assignedToAdminId: id });
                setFieldValue("assignedToAdminId", id);
              }}
              loading={loading}
            />
          </Form>
        )}
      </Formik>
    );
  }
  return (
    <Button
      className="secondary-btn"
      onClick={(e) => {
        e.stopPropagation();
        setCanAssign(true);
      }}
    >
      Assign
    </Button>
  );
};

export default Assign;
