
import {serializable, alias, object, list, primitive} from 'serializr';

export class Comment { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('issue_id', primitive()))
	issueId?: number;

	@serializable(alias('commentor_type', primitive()))
	commentorType?: string;

	@serializable(alias('commentor_id', primitive()))
	commentorId?: number;

	@serializable(alias('commentor_first_name', primitive()))
	commentorFirstName?: string;

	@serializable(alias('commentor_last_name', primitive()))
	commentorLastName?: string;

	@serializable(alias('commentor_profile_pic_url', primitive()))
	commentorProfilePicUrl?: string;

	@serializable(alias('comment', primitive()))
	comment?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

}