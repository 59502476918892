import React from "react";
import { Row, Col } from "antd";
import "./fileDetail.scss";
import { getFileIcon } from "../../utils/getFileIcon";

interface FileDetailProps {
  name: string;
}

function FileDetail({ name }: FileDetailProps) {
  return (
    <div className="file-detail">
      <Row align="middle">
        <Col span={6}>
          <img
            src={getFileIcon(name)}
            alt="File icon"
            className="file-detail__file-icon"
          />
        </Col>
        <Col span={17} offset={1}>
          {name}
        </Col>
      </Row>
    </div>
  );
}

export default FileDetail;
