import React, { useEffect, useState } from "react";
import "./objectLinkList.scss";
import { ObjectLink } from "../../../models/ObjectLink/objectLink.model";
import ObjectLinkService from "../../../services/ObjectLink/objectLink.service";
import AppLoader from "../../../shared/components/AppLoader";
import { Button, Modal, Table } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link, generatePath, useParams } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { ResponsibilityTypes } from "../../../enums/responsibility.enum";

interface ObjectLinkListProps extends AuthReducerProps {
  dentalObjectId?: number;
}

interface ObjectLinkParamsProps {
  dentalObjectId?: string;
}

function ObjectLinkList({
  dentalObjectId,
  userResponsibilities,
}: ObjectLinkListProps) {
  const params: ObjectLinkParamsProps = useParams();

  const [loading, setLoading] = useState(false);

  const [objectLinks, setObjectLinks] = useState<ObjectLink[]>([]);

  const handleDelete = (objectLinkId?: number) => () => {
    if (objectLinkId)
      Modal.confirm({
        title: "Confirm",
        icon: null,
        content: "Are you sure want to delete the link?",
        onOk: () => {
          ObjectLinkService.deleteObjectLink(
            objectLinkId,
            () => {
              const objectLinkIndex = objectLinks.findIndex(
                (link) => link.id === objectLinkId
              );
              if (objectLinkIndex >= 0) objectLinks.splice(objectLinkIndex, 1);
              setObjectLinks([...objectLinks]);
            },
            () => {},
            () => {}
          );
        },
      });
  };

  useEffect(() => {
    if (dentalObjectId) {
      setLoading(true);
      ObjectLinkService.fetchObjectLinks(
        dentalObjectId,
        (objectLinks: ObjectLink[]) => {
          setObjectLinks(objectLinks);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  const isCurrentObject = (destObjectId?: number) => {
    if (dentalObjectId === destObjectId) {
      return true;
    }
    return false;
  };

  const columns = [
    {
      title: "Current Locator",
      dataIndex: "srcLocatorName",
      key: "srcLocatorName",
      render: (srcLocatorName: string, link: ObjectLink) => {
        if (isCurrentObject(link?.destObjectId)) {
          return link.destLocatorName;
        }
        return srcLocatorName;
      },
    },
    {
      title: "Second Object",
      dataIndex: "destObjectName",
      key: "destObjectName",
      render: (destObjectName: string, link: ObjectLink) => {
        if (isCurrentObject(link?.destObjectId)) {
          return link.srcObjectName;
        }
        return destObjectName;
      },
    },
    {
      title: "Second Locator",
      dataIndex: "destLocatorName",
      key: "destLocatorName",
      render: (destLocatorName: string, link: ObjectLink) => {
        if (isCurrentObject(link?.destObjectId)) {
          return link.srcLocatorName;
        }
        return destLocatorName;
      },
    },
    {
      title: "Friendly Name",
      dataIndex: "secondObjectFriendlyName",
      key: "secondObjectFriendlyName",
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (text: string, record: any, index: number) => {
        if (!userResponsibilities.includes(ResponsibilityTypes.LINK_DELETE)) {
          return null;
        }

        return (
          <Button
            className="danger-btn"
            icon={<DeleteOutlined />}
            onClick={handleDelete(record?.id)}
          />
        );
      },
    },
  ];
  
  return (
    <div className="object-link-list">
      <div className="mb-5 text-right">
        {userResponsibilities.includes(ResponsibilityTypes.LINK_CREATE) && (
          <Link
            to={generatePath(AppRoutes.ADD_OBJECT_LINK, {
              dentalObjectId: params.dentalObjectId,
            })}
          >
            <Button type="primary">
              <PlusOutlined />
              Add Link
            </Button>
          </Link>
        )}
      </div>
      <div className="object-link-list__table">
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table
            dataSource={objectLinks}
            columns={columns}
            expandable={{
              expandedRowRender: (record) => <p style={{ margin: 0 }}>{}</p>,
              rowExpandable: (record) => true,
              expandRowByClick: true,
            }}
            expandRowByClick
          />
        )}
      </div>
    </div>
  );
}

export default AuthContainer(ObjectLinkList);
