import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { City } from "../../models/City/city.model";
import { Country } from "../../models/Country/country.model";
import { Province } from "../../models/Province/province.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";

class UserMetaService {
  static async getService(
    onSuccess: (countries: Country[]) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.COUNTRIES);
      const countries = deserialize(Country, data["countries"] as any[]);
      onSuccess(countries);
    } catch (error) {
      console.log(error?.message);
    } finally {
      onFinal();
    }
  }

  static async getProvinces(
    countryId: string,
    onSuccess: (provinces: Province[]) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.PROVINCES.replace(":countryId", countryId);
      const { data } = await axiosInstance.get(URL);
      const provinces = deserialize(Province, data["provinces"] as any[]);
      onSuccess(provinces);
    } catch (error) {
      console.log(error?.message);
    } finally {
      onFinal();
    }
  }

  static async getCities(
    countryId: string,
    provinceId: string,
    onSuccess: (countries: City[]) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.CITIES.replace(":countryId", countryId).replace(
        ":provinceId",
        provinceId
      );
      const { data } = await axiosInstance.get(URL);
      const cities = deserialize(City, data["cities"] as any[]);
      onSuccess(cities);
    } catch (error) {
      console.log(error?.message);
    } finally {
      onFinal();
    }
  }
}

export default UserMetaService;
