export const responsibilities = [
  {
    id: 1,
    title: "Corporate management",
    responsibility: [
      {
        id: 11,
        title: "Corporate approval",
        approval: [
          "corporate_approval_create",
          "corporate_approval_view",
          "corporate_approval_edit",
          "corporate_approval_delete",
        ],
      },
      {
        id: 12,

        title: "Corporate management",
        approval: [
          "corporate_create",
          "corporate_view",
          "corporate_edit",
          "corporate_delete",
        ],
      },
    ],
  },
  {
    id: 2,

    title: "3D core",
    responsibility: [
      {
        id: 21,

        title: "Object management",
        approval: [
          "dental_object_create",
          "dental_object_view",
          "dental_object_edit",
          "dental_object_delete",
        ],
      },
      {
        id: 22,

        title: "Relation/Link management",
        approval: ["link_create", "link_view", "link_edit", "link_delete"],
      },
    ],
  },
  {
    id: 3,

    title: "User management",
    responsibility: [
      {
        id: 31,

        title: "Internal users",
        approval: ["user_create", "user_view", "user_edit", "user_delete"],
      },
      {
        id: 32,

        title: "Roles & responsibilities",
        approval: ["role_create", "role_view", "role_edit", "role_delete"],
      },
    ],
  },
];
