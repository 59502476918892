import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import "./dentalObjectForm.scss";
import { useHistory, useParams, generatePath } from "react-router-dom";
import { dentalObjectFormValidation } from "./dentalObjectFormValidation";
import DentalObjectPreview from "../DentalObjectPreview";
import { DentalObject } from "../../../models/DentalObject/dentalObject.model";
import DentalObjectService from "../../../services/DentalObject/dentalObject.service";
import { ObjectCategory } from "../../../models/ObjectCategory/objectCategory.model";
import {
  IDropdownOptions,
  MetaService,
} from "../../../services/Meta/meta.service";
import { Manufacturer } from "../../../models/Manufacturer/manufacturer.model";
import { ObjectModel } from "../../../models/ObjectModel/objectModel.model";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { FormActionEnum } from "../../../enums/formAction.enum";

interface DentalObjectFormProps {}

interface DentalObjectParams {
  dentalObjectId?: string;
}

function DentalObjectForm(props: DentalObjectFormProps) {
  const history = useHistory();

  const params: DentalObjectParams = useParams();

  const [formAction, setFormAction] = useState<string>(FormActionEnum.ADD);

  const [objectCategoryOptions, setObjectCategoryOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [objectModelOptions, setObjectModelOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [manufacturerOptions, setManufacturerOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [formLoading, setFormLoading] = useState(false);

  const [formValues, setFormValues] = useState<DentalObject>(
    new DentalObject()
  );

  const [objectUrl, setObjectUrl] = useState<string | undefined>();

  const objectTypes = [
    { label: "Anatomy", value: "anatomy" },
    { label: "Vendor", value: "vendor" },
  ];

  const handleSubmit = (values: DentalObject) => {
    const dentalObject = Object.assign(new DentalObject(), values);
    setFormLoading(true);
    if (dentalObject.id)
      if (formAction === FormActionEnum.CLONE) {
        DentalObjectService.cloneDentalObject(
          dentalObject,
          (dentalObject: DentalObject) => {
            history.push(
              generatePath(AppRoutes.ADD_OBJECT_LINK, {
                dentalObjectId: dentalObject.id,
              })
            );
          },
          () => {},
          () => {
            setFormLoading(false);
          }
        );
      } else {
        DentalObjectService.updateDentalObject(
          dentalObject,
          (dentalObject: DentalObject) => {
            history.push(
              generatePath(AppRoutes.ADD_OBJECT_LINK, {
                dentalObjectId: dentalObject.id,
              })
            );
          },
          () => {},
          () => {
            setFormLoading(false);
          }
        );
      }
    else
      DentalObjectService.createDentalObject(
        dentalObject,
        (dentalObject: DentalObject) => {
          history.push(
            generatePath(AppRoutes.ADD_OBJECT_LINK, {
              dentalObjectId: dentalObject.id,
            })
          );
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
  };

  const handleCancel = () => history.goBack();

  useEffect(() => {
    if (params.dentalObjectId) {
      DentalObjectService.showDentalObject(
        Number(params.dentalObjectId),
        (dentalObject: DentalObject) => {
          setFormValues(dentalObject);
          setObjectUrl(dentalObject.attachmentUrl);
          if (dentalObject.objectType)
            handleFetchObjectCategories(dentalObject.objectType);
          if (dentalObject.objectCategoryId)
            handleFetchObjectModels(dentalObject.objectCategoryId);
        },
        () => {},
        () => {}
      );
    }
  }, [params.dentalObjectId]);

  useEffect(() => {
    setFormAction(
      history.location.pathname.includes(FormActionEnum.CLONE)
        ? FormActionEnum.CLONE
        : history.location.pathname.includes(FormActionEnum.EDIT)
        ? FormActionEnum.EDIT
        : FormActionEnum.ADD
    );
  }, [history.location.pathname]);

  const handleFetchObjectCategories = (objectType: string) => {
    MetaService.fetchObjectCategories(
      objectType,
      (objectCategories: ObjectCategory[]) => {
        setObjectCategoryOptions(
          objectCategories.map((objectCategory) => ({
            label: objectCategory.name,
            value: objectCategory.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  };

  const handleFetchObjectModels = (objectCategoryId: number) => {
    MetaService.fetchObjectModels(
      objectCategoryId,
      (objectModels: ObjectModel[]) => {
        setObjectModelOptions(
          objectModels.map((objectModel) => ({
            label: objectModel.name,
            value: objectModel.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    MetaService.fetchManufacturers(
      (manufacturers: Manufacturer[]) => {
        setManufacturerOptions(
          manufacturers.map((manufacturer) => ({
            label: manufacturer.name,
            value: manufacturer.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  }, []);

  return (
    <div className="object-3d-form">
      <Row>
        <Col span={12}>
          <h5>
            <span className="text-capitalize">{formAction}</span> Object
          </h5>
        </Col>
      </Row>
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={dentalObjectFormValidation}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <Form>
              <Row gutter={[20, 0]}>
                <Col span={16}>
                  <div className="object-3d-form__model">
                    <DentalObjectPreview
                      objectUrl={objectUrl}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </Col>
                <Col span={8}>
                  <div className="object-3d-form__form">
                    <Row gutter={[20, 0]}>
                      <Col span={24}>
                        <AttachmentUpload
                          accept="application/babylon"
                          name="attachment"
                          placeholder="Upload Object"
                          newAttachments={
                            values.attachment ? [values.attachment] : []
                          }
                          onChange={(e) => {
                            if (e.target.files.length > 0) {
                              const url = URL.createObjectURL(
                                e.target.files[0]
                              );
                              setObjectUrl(url);
                              setFieldValue("attachment", e.target.files[0]);
                            }
                          }}
                          attachments={[]}
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                      <Col span={24}>
                        <InputField
                          title="Model Name"
                          type="text"
                          name="name"
                          placeholder="Enter model name"
                          // readOnly
                        />
                      </Col>
                      <Col span={24}>
                        <InputField
                          title="Friendly Name"
                          type="text"
                          name="friendlyName"
                          placeholder="Enter Friendly name"
                        />
                      </Col>
                      <Col span={24}>
                        <DropdownField
                          title="Type"
                          name="objectType"
                          placeHolder="Select Type"
                          onChange={(value) => {
                            setFieldValue("objectType", value);
                            handleFetchObjectCategories(value);
                          }}
                          options={objectTypes}
                          value={values.objectType}
                        />
                      </Col>
                      <Col span={24}>
                        <DropdownField
                          title="Category"
                          placeHolder="Select Category"
                          name="objectCategoryId"
                          setFieldValue={setFieldValue}
                          options={objectCategoryOptions}
                          onChange={(value) => {
                            setFieldValue("objectCategoryId", value);
                            handleFetchObjectModels(value);
                          }}
                          value={values.objectCategoryId}
                        />
                      </Col>
                      <Col span={24}>
                        <DropdownField
                          title="Model"
                          name="objectModelId"
                          placeHolder="Select Model"
                          setFieldValue={setFieldValue}
                          options={objectModelOptions}
                          value={values.objectModelId}
                        />
                      </Col>
                      <Col span={24}>
                        <DropdownField
                          title="Manufacturer"
                          name="manufacturerId"
                          placeHolder="Select Manufacturer"
                          setFieldValue={setFieldValue}
                          options={manufacturerOptions}
                          value={values.manufacturerId}
                        />
                      </Col>
                      <Col span={24}>
                        <InputField
                          title="Description"
                          type="textarea"
                          name="description"
                          placeholder="Enter Description"
                        />
                      </Col>
                      <Col span={24}>
                        <InputField
                          title="Color"
                          type="color"
                          name="color"
                          placeholder="Enter Color"
                        />
                        <div>
                          Note: Picking white color will reset to default color
                        </div>
                      </Col>
                    </Row>
                    <div className="object-3d-form__submit-wrapper text-right">
                      <Button type="default" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        className="ml-2"
                        htmlType="submit"
                        loading={formLoading}
                        disabled={!isValid || !dirty || formLoading}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default DentalObjectForm;
