import React from 'react';
import { DatePicker } from 'antd';
import {ErrorMessage, Field} from "formik";
import Error from "../Error";
import "./datePickerField.scss";
import moment, {Moment} from "moment";

interface DatePickerFieldProps {
    name: string;
    title?: string;
    setFieldValue: Function;
    placeholder?: string;
    defaultValue?: string;
    disabled?: boolean;
    disabledDate?: (date: Moment) => boolean;
    picker?: any;
    format?: string;
}

function DatePickerField(props: DatePickerFieldProps) {

    const {
        title,
        placeholder,
        name,
        format,
        picker,
        disabledDate,
        setFieldValue,
        defaultValue,
        disabled } = props;

    const handleChange = (date: any, dateString: any) => {
        setFieldValue(name, date.format('YYYY-MM-DD'));
    };

    return (
        <Field name={name}>
            {({ field, form: { touched, errors }, meta }: any) => (
                <div className='date-picker-field'>
                    { title && <div className='date-picker-field__title'>{ title }</div> }
                    <DatePicker
                        className={`date-picker-${name}`}
                        disabled={disabled}
                        picker={picker}
                        onChange={handleChange}
                        disabledDate={disabledDate ? disabledDate : undefined}
                        value={ defaultValue ? moment(defaultValue) : undefined}
                        format={format || 'DD/MM/YYYY'}
                        name={name}
                        suffixIcon={<i className="icon-calendar"/>}
                    />
                    <ErrorMessage name={name}>
                        {(message: string) => <Error message={message} />}
                    </ErrorMessage>
                </div>
            )}
        </Field>
    );
}

export default DatePickerField;
