
import {serializable, alias, object, list, primitive} from 'serializr';

export class SubscriptionPayment { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('stripe_payment_intent_id', primitive()))
	stripePaymentIntentId?: string;

	@serializable(alias('stripe_payment_intent_status', primitive()))
	stripePaymentIntentStatus?: string;

	@serializable(alias('stripe_payment_intent_amount', primitive()))
	stripePaymentIntentAmount?: number;

	@serializable(alias('stripe_subscription_id', primitive()))
	stripeSubscriptionId?: string;

	@serializable(alias('stripe_user_type', primitive()))
	stripeUserType?: string;

	@serializable(alias('stripe_user_id', primitive()))
	stripeUserId?: string;

	@serializable(alias('corporate_subscription_id', primitive()))
	corporateSubscriptionId?: number;

	@serializable(alias('corporate_id', primitive()))
	corporateId?: number;

	@serializable(alias('corporate_name', primitive()))
	corporateName?: string;

	@serializable(alias('user_type', primitive()))
	userType?: string;

	@serializable(alias('user_id', primitive()))
	userId?: number;

	@serializable(alias('subscription_name', primitive()))
	subscriptionName?: string;

	@serializable(alias('subscription_interval', primitive()))
	subscriptionInterval?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

}