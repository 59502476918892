import axiosInstance from "../../interceptor/axiosInstance";
import { store } from "../../store";
import { AUTHENTICATED } from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { Admin } from "../../models/Admin/admin.model";
import { deserialize } from "serializr";

export default class AuthService {
  static login(
    code: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const params = new URLSearchParams();
    params.append("code", code);
    return axiosInstance
      .post(ApiRoutes.TOKEN, params)
      .then((response) => {
        localStorage.setItem("token", response.data.id_token);
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
          },
        });
        Notification({
          message: "Login",
          description: "Logged in successfully",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Login failed",
          description: "incorrect email or password",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(onFinal);
  }

  static async getAdminDetail(onSuccess: (admin: Admin) => void) {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.ADMIN_DETAILS);
      const admin = deserialize(Admin, data?.admin);
      onSuccess(admin);
    } catch (error) {}
  }
}
