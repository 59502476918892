import {serializable, alias, primitive} from 'serializr';

export class Corporate {

    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('name', primitive()))
    name?: string;

    @serializable(alias('status', primitive()))
    status?: boolean = true;

    @serializable(alias('appliance_ratings', primitive()))
    applianceRatings?: boolean;

    @serializable(alias('contact_person', primitive()))
    contactPerson?: string;

    @serializable(alias('isd_code', primitive()))
    isdCode?: string;

    @serializable(alias('address_line_1', primitive()))
    addressLine1?: string;

    @serializable(alias('address_line_2', primitive()))
    addressLine2?: string;

    @serializable(alias('mobile_number', primitive()))
    mobileNumber?: string;

    @serializable(alias('primary_email', primitive()))
    primaryEmail?: string;

    @serializable(alias('state', primitive()))
    state?: string;

    @serializable(alias('city_id', primitive()))
    cityId?: string;

    @serializable(alias('city_name', primitive()))
    cityName?: string;

    @serializable(alias('country_id', primitive()))
    countryId?: string;

    @serializable(alias('country_name', primitive()))
    co?: string;

    @serializable(alias('nationality', primitive()))
    nationality?: string;

    @serializable(alias('zip_code', primitive()))
    zipCode?: string;

    @serializable(alias('zipcode_id', primitive()))
    zipCodeId?: string;

    @serializable(alias('time_zone', primitive()))
    timeZone?: string;

    @serializable(alias('no_of_practice', primitive()))
    noOfPractices?: number;

    @serializable(alias('no_of_staff', primitive()))
    noOfStaffs?: number;

    @serializable(alias('website_url', primitive()))
    websiteUrl?: string;

    @serializable(alias('linkedin_url', primitive()))
    linkedinUrl?: string;

    @serializable(alias('facebook_url', primitive()))
    facebookUrl?: string;

    @serializable(alias('twitter_url', primitive()))
    twitterUrl?: string;

    @serializable(alias('logo_url', primitive()))
    logoUrl?: string;

    @serializable(alias('business_license_validity', primitive()))
    businessLicenseValidity?: boolean = false;

    @serializable(alias('elastic_compliance', primitive()))
    elasticCompliance?: boolean;

    @serializable(alias('license_url', primitive()))
    licenseUrl?: string;

    @serializable(alias('note_sorting', primitive()))
    noteSorting?: boolean;

    @serializable(alias('open_records_locked', primitive()))
    openRecordsLocked?: string;

    @serializable(alias('oral_hygiene', primitive()))
    oralHygiene?: boolean;

    @serializable(alias('physical_presence', primitive()))
    physicalPresence?: boolean = false;

    @serializable(alias('province_id', primitive()))
    provinceId?: string;

    @serializable(alias('province_name', primitive()))
    provinceName?: string;

    @serializable(alias('register_number', primitive()))
    registerNumber?: string;

    @serializable(alias('req_pass_to_change_assistant', primitive()))
    reqPassToChangeAssistant?: string;

    @serializable(alias('secondary_email', primitive()))
    secondaryEmail?: string;

    @serializable(alias('show_medical_alerts', primitive()))
    showMedicalAlerts?: boolean;

    @serializable(alias('social_presence', primitive()))
    socialPresence?: boolean = false;

    @serializable(alias('sterilization_code', primitive()))
    sterilizationCode?: string;

    @serializable(alias('subscription_package', primitive()))
    subscriptionPackage?: string;

    @serializable(alias('subscription_name', primitive()))
    subscriptionName?: string;

    @serializable(alias('tax', primitive()))
    tax?: string;

    @serializable(alias('tooth_numbering_system', primitive()))
    toothNumberingSystem?: string;

    @serializable(alias('reject_reason', primitive()))
    rejectReason?: string;

}
