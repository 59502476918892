import { Table } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { StatsTypeEnum } from "../../../../enums/statsType.enum";
import { Metrics } from "../../../../models/Metrics/metrics.model";
import { Patient } from "../../../../models/Patient/patient.model";
import CorporateService from "../../../../services/Corporate/corporate.service";
import MetricsService from "../../../../services/Metrics/metrics.service";
import AppLoader from "../../../../shared/components/AppLoader";
import Stats from "../../../../shared/components/Stats";
import "./patients.scss";

interface PatientsProps {}

const Patients: FC<PatientsProps> = (props) => {
  const {} = props;

  const [patients, setPatients] = useState<Patient[]>([]);
  const [loading, setLoading] = useState(false);

  const params = useParams<{ corporateId: string }>();
  const [metrics, setMetrics] = useState<Metrics>();
  console.log(params.corporateId);

  useEffect(() => {
    setLoading(true);
    CorporateService.fetchCorporatePatients(
      params.corporateId,
      (patients) => {
        setPatients(patients);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );

    MetricsService.fetchMetrics(
      params?.corporateId,
      "patients",
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {}
    );
  }, []);

  const columns = [
    {
      title: "Patient ID",
      dataIndex: "id",
      key: "id",
    },

    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Age",
      dataIndex: "dob",
      key: "dob",
      render: (dob: string) => <span>{dob.replace("T00:00:00", "")}</span>,
    },
    {
      title: "Location",
      dataIndex: "cityName",
      key: "cityName",
    },
  ];
  const statsData = [
    {
      title: "Number of Patients",
      value: metrics?.totalNoOfPatients ?? 0,
    },
    {
      title: "Active Patients In Contract",
      value: metrics?.activePatients ?? 0,
    },
    {
      title: "Patients In Retention Phase",
      value: metrics?.patientsWithoutFutureAppointments ?? 0,
    },
    {
      title: "Inactive Patients (Discharged)",
      value: metrics?.inactivePatients ?? 0,
    },
  ];
  return (
    <div className="patients">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <>
          <Stats statsData={statsData} statsType={StatsTypeEnum.PATIENTS} />
          <Table dataSource={patients} columns={columns} />
        </>
      )}
    </div>
  );
};

export default Patients;
