import React, {useState} from "react";
import {Form, Formik, FormikValues} from "formik";
import "./corporateForm.scss";
import {Corporate} from "../../../models/Corporate/corporate.model";
import {Row, Col, Button} from "antd";
import {corporateFormValidation} from "./corporateFormValidation";
import InputField from "../../../shared/components/InputField";
import CorporateService from "../../../services/Corporate/corporate.service";

interface CorporateFormProps {
    onCancel: () => void;
    onSuccess: () => void;
}

function CorporateForm(props: CorporateFormProps) {

    const {onCancel, onSuccess} = props;

    const [formValues, setFormValues] = useState(new Corporate());

    const [formLoading, setFormLoading] = useState(false);

    const handleSubmit = (values: FormikValues) => {
        const corporate = Object.assign(new Corporate(), values);
        setFormLoading(true);
        CorporateService.inviteCorporate(corporate, () => {
            onSuccess();
        }, () => {}, () => {
            setFormLoading(false);
        });
    };

    return (
        <div className='corporate-form'>
            <Formik
                initialValues={formValues}
                onSubmit={ handleSubmit }
                enableReinitialize
                validationSchema={corporateFormValidation}>
                {({ handleSubmit, handleChange, values, errors, isValid, dirty, setFieldValue }) => {
                    return (
                        <Form>
                            <Row gutter={[20, 0]} className='corporate-form__fields'>
                                <Col span={12}>
                                    <InputField
                                        title="Corporate Name"
                                        type="text"
                                        name="name"
                                        placeholder="Enter Corporate name" />
                                </Col>
                                <Col span={12}>
                                    <InputField
                                        title="Contact person"
                                        type="text"
                                        name="contactPerson"
                                        placeholder="Enter Contact person " />
                                </Col>
                                <Col span={12}>
                                    <InputField
                                        title="Primary Email"
                                        type="email"
                                        name="primaryEmail"
                                        placeholder="Enter email " />
                                </Col>
                            </Row>
                            <div
                                className='practice-form__submit-wrapper text-right'>
                                <Button
                                    onClick={onCancel}
                                    type="default">
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    className='ml-2'
                                    htmlType="submit"
                                    loading={formLoading}
                                    disabled={ (!isValid) || !dirty || (formLoading) }>
                                    Send Invite
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

export default CorporateForm;
