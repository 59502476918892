import React, { Fragment, useEffect, useState } from "react";
import { Menu, Row, Col } from "antd";
import "./appSidebar.scss";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import avatarPlaceholder from "../../../assets/images/userPlaceholder.png";
import Badge from "../Badge";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { ResponsibilityTypes } from "../../../enums/responsibility.enum";

const menuItems = [
  {
    path: AppRoutes.DASHBOARD,
    hasResponsibility: ResponsibilityTypes.CORPORATE_APPROVAL_VIEW,
    icon: "icon-dashboard",
    text: "Dashboard",
  },
  {
    path: AppRoutes.CORPORATES,
    hasResponsibility: ResponsibilityTypes.CORPORATE_VIEW,
    icon: "icon-Corporates",
    text: "Corporates",
  },
  {
    path: AppRoutes.SUBSCRIPTIONS,
    hasResponsibility: false,
    icon: "icon-subscriptions",
    text: "Subscriptions",
  },
  {
    path: AppRoutes.PAYMENTS,
    hasResponsibility: false,
    icon: "icon-payments",
    text: "Payments",
  },
  {
    path: AppRoutes.CORE_3D,
    hasResponsibility: ResponsibilityTypes.DENTAL_OBJECT_VIEW,
    icon: "icon-d-core",
    text: "3D Core",
  },
  {
    path: AppRoutes.XD_ANALYTICS,
    hasResponsibility: false,
    icon: "icon-analytics",
    text: "XD - Analytics",
  },
  {
    path: AppRoutes.SUPPORT,
    hasResponsibility: false,
    icon: "icon-support",
    text: "Support",
  },
  {
    path: AppRoutes.SETTINGS,
    hasResponsibility: false,
    icon: "icon-settings",
    text: "Settings",
    children: [
      {
        path: AppRoutes.USERS,
        hasResponsibility: ResponsibilityTypes.USER_VIEW,
        icon: "icon-users",
        text: "Users",
      },
      {
        path: AppRoutes.ROLES_AND_PERMISSIONS,
        hasResponsibility: false,
        icon: "icon-support",
        text: "Roles & Permissions",
      },
    ],
  },
];

interface AppSidebarProps extends AuthReducerProps {}

function AppSidebar({ authenticated, userResponsibilities }: AppSidebarProps) {
  const history = useHistory();

  const [pathname, setPathname] = useState<string>(history.location.pathname);

  const handleClick = ({ key }: any) => {
    if (key !== "profile") history.push(key);
  };

  const handleLogout = () => {};

  useEffect(() => {
    return history.listen((location) => {
      setPathname(location.pathname);
    });
  }, [history]);

  return authenticated ? (
    <div className="app-sidebar">
      <Menu
        className="app-sidebar__menubar"
        onClick={handleClick}
        defaultSelectedKeys={["dashboard"]}
        selectedKeys={[pathname]}
        mode="vertical"
      >
        {menuItems.map((menuItem) => {
          if (menuItem.children) {
            return (
              <Menu.SubMenu
                key={menuItem.path}
                className="app-sidebar__submenu"
                disabled={
                  typeof menuItem.hasResponsibility === "string"
                    ? !userResponsibilities.includes(menuItem.hasResponsibility)
                    : false
                }
                title={
                  <div className="sub-menu__content-wrapper">
                    <div className="screen-over" />
                    <i className={`${menuItem.icon} icon`} />
                    <div className="app-sidebar__menu-title name">
                      {menuItem.text}
                    </div>
                  </div>
                }
              >
                {menuItem.children.map((childItem) => (
                  <Menu.Item
                    key={childItem.path}
                    className="app-sidebar__submenu-item"
                    disabled={
                      typeof menuItem.hasResponsibility === "string"
                        ? !userResponsibilities.includes(
                            menuItem.hasResponsibility
                          )
                        : false
                    }
                  >
                    <div className="screen-over" />
                    <i className={`${childItem.icon} icon`} />
                    <div className="app-sidebar__menu-title name">
                      {childItem.text}
                    </div>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          }
          return (
            <Menu.Item
              key={menuItem.path}
              disabled={
                menuItem.hasResponsibility
                  ? !userResponsibilities.includes(
                      menuItem.hasResponsibility as string
                    )
                  : false
              }
            >
              <div className="screen-over" />
              <i className={`${menuItem.icon} icon`} />
              <div className="app-sidebar__menu-title name">
                {menuItem.text}
              </div>
            </Menu.Item>
          );
        })}
        <Menu.Item className="app-sidebar__profile" key="profile">
          <Row gutter={[10, 0]}>
            <Col span={6}>
              <img
                src={avatarPlaceholder}
                className="app-sidebar__profile-img"
                alt="User Placeholder"
              />
            </Col>
            <Col span={18}>
              <div className="app-sidebar__profile-info">
                <h5>Dr. William Dean</h5>
                <Badge>Doctor</Badge>
              </div>
            </Col>
          </Row>
        </Menu.Item>
      </Menu>
    </div>
  ) : null;
}

export default AuthContainer(AppSidebar);