import { Button, Col, Row, Switch } from "antd";
import React, { FC } from "react";
import Subscription from "../../../models/Subscription/subscription.model";
import { EditOutlined } from "@ant-design/icons";
import "./viewSubscription.scss";
import SupportService from "../../../services/Support/support.service";
import { useState } from "react";
import { useEffect } from "react";
import SubscriptionService from "../../../services/Subscription/subscription.service";

interface ViewSubscriptionProps {
  subscription?: Subscription;
  editSubscriptionHandler: (subscription?: Subscription) => void;
}

const ViewSubscription: FC<ViewSubscriptionProps> = (props) => {
  const { subscription, editSubscriptionHandler } = props;

  const [checked, setChecked] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setChecked(subscription?.isEnabled ?? false);
  }, [subscription]);

  const handleEditSubscriptionHandler = () => {
    editSubscriptionHandler(subscription);
  };

  const handleEnable = (enabled: boolean) => {
    setLoading(true);
    const updatedSubscription = Object.assign(new Subscription(), {
      ...subscription,
      isEnabled: enabled,
    });
    SubscriptionService.updateSubscription(
      updatedSubscription,
      (subscription) => {
        setChecked(subscription?.isEnabled);
      },
      () => {
        setLoading(false);
      }
    );
  };

  return (
    <div className="view-subscription">
      <Row justify="end">
        <Col span={4}>
          <Button type="primary" onClick={handleEditSubscriptionHandler}>
            <EditOutlined /> Edit
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <label>Subscription name</label>
          <p>{subscription?.subscriptionName}</p>
        </Col>
        <Col span={8}>
          <label>Max no of practices</label>
          <p>{subscription?.maxNoOfPractices}</p>
        </Col>
        <Col span={8}>
          <label>Max no of staffs</label>
          <p>{subscription?.maxNoOfStaff}</p>
        </Col>
        <Col span={8}>
          <label>Max no of patients</label>
          <p>{subscription?.maxNoOfPatients}</p>
        </Col>
        <Col span={24}>
          <label>Trial Periods</label>
          <p>{subscription?.trialPeriod} days</p>
        </Col>
        <Col span={24}>
          <label>Feature Description</label>
          <p>{subscription?.featureDescription}</p>
        </Col>
        <Col span={24}>
          Enable/Disable{" "}
          <Switch
            checked={checked}
            onChange={handleEnable}
            loading={loading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ViewSubscription;
