import { Table } from "antd";
import React, { FC } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Metrics } from "../../../../models/Metrics/metrics.model";
import { Practice } from "../../../../models/Practice/practice.model";
import CorporateService from "../../../../services/Corporate/corporate.service";
import MetricsService from "../../../../services/Metrics/metrics.service";
import AppLoader from "../../../../shared/components/AppLoader";
import Stats from "../../../../shared/components/Stats";
import "./practices.scss";

interface PracticesProps {}

const Practices: FC<PracticesProps> = (props) => {
  const {} = props;

  const params = useParams<{ corporateId: string }>();
  const [metrics, setMetrics] = useState<Metrics>();
  const [loading, setLoading] = useState(false);

  const [practices, setPractices] = useState<Practice[]>([]);
  const statsData = [
    {
      title: "Total Number of practices",
      value: metrics?.totalNoOfPractices ?? 0,
    },
    {
      title: "Active Practices",
      value: metrics?.activePractices ?? 0,
    },
    {
      title: "Inactive practices",
      value: metrics?.inactivePractices ?? 0,
    },
  ];

  useEffect(() => {
    setLoading(true);
    CorporateService.fetchCorporatePractices(
      params?.corporateId,
      (practices) => {
        setPractices(practices);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
    MetricsService.fetchMetrics(
      params?.corporateId,
      "practices",
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {}
    );
  }, []);

  const columns = [
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "Chairs",
      dataIndex: "noOfChairs",
      key: "noOfChairs",
    },
    {
      title: "Time Zone",
      dataIndex: "timeZone",
      key: "timeZone",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span className="text-success">{status}</span>
      ),
    },
  ];

  return (
    <div className="practices">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <>
          <Stats statsData={statsData} />
          <Table dataSource={practices} columns={columns} />
        </>
      )}
    </div>
  );
};

export default Practices;
