import { Button } from "antd";
import React, { FC } from "react";
import "./subscription.scss";
import { CheckOutlined } from "@ant-design/icons";
import { useState } from "react";
import SubscriptionModel from "../../../../models/Subscription/subscription.model";
import { useEffect } from "react";
import SupportService from "../../../../services/Support/support.service";
import SubscriptionService from "../../../../services/Subscription/subscription.service";
import SubscriptionFrequency from "../../../../models/SubscriptionFrequency/subscriptionFrequency.model";
import { useParams } from "react-router-dom";

interface SubscriptionProps {}

const benefits = ["10 Practices", "50 Staffs", "10k Patients"];

const features = Array(5).fill(["Document management system for patients"]);

const Subscription: FC<SubscriptionProps> = (props) => {
  const {} = props;

  const params = useParams<{ corporateId: string }>();

  const [
    subscriptionFrequency,
    setSubscriptionFrequency,
  ] = useState<SubscriptionFrequency>();

  useEffect(() => {
    SubscriptionService.getSubscriptionByCorporate(
      params?.corporateId,
      (subscriptionFrequency) => {
        setSubscriptionFrequency(subscriptionFrequency);
      },
      () => {}
    );
  }, []);

  if (!subscriptionFrequency) {
    return null;
  }

  return (
    <div className="subscription">
      <p>{subscriptionFrequency?.subscription?.name}</p>
      {subscriptionFrequency?.subscription?.trialPeriod && (
        <h1>Trial Period</h1>
      )}
      {subscriptionFrequency?.subscriptionType && (
        <h1>
          ${subscriptionFrequency?.recurringAmount} /{" "}
          {subscriptionFrequency?.subscriptionType}
        </h1>
      )}

      <ul className="benefits">
        <li>
          <CheckOutlined />{" "}
          {subscriptionFrequency?.subscription?.maxNoOfPractices} Practices
        </li>
        <li>
          <CheckOutlined /> {subscriptionFrequency?.subscription?.maxNoOfStaffs}{" "}
          Staffs
        </li>
        <li>
          <CheckOutlined />{" "}
          {subscriptionFrequency?.subscription?.maxNoOfPatients} Patients
        </li>
      </ul>
      <p className="feature">Feature description</p>
      <ul className="features-list">
        <li>{subscriptionFrequency?.subscription?.description}</li>
      </ul>
    </div>
  );
};

export default Subscription;
