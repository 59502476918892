
import {PaymentIntent} from '../PaymentIntent/paymentIntent.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class PaymentTransfer { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('stripe_transfer_id', primitive()))
	stripeTransferId?: string;

	@serializable(alias('stripe_transfer_amount', primitive()))
	stripeTransferAmount?: number;

	@serializable(alias('stripe_transfer_status', primitive()))
	stripeTransferStatus?: string;

	@serializable(alias('stripe_account_id', primitive()))
	stripeAccountId?: string;

	@serializable(alias('stripe_payment_intent_id', primitive()))
	stripePaymentIntentId?: string;

	@serializable(alias('corporate_id', primitive()))
	corporateId?: number;

	@serializable(alias('payment_intent_id', primitive()))
	paymentIntentId?: number;

	@serializable(alias('payment_intent', object(PaymentIntent)))
	paymentIntent?: PaymentIntent;

}