import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { ObjectLink } from "../../models/ObjectLink/objectLink.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class ObjectLinkService {
  static fetchObjectLinks(
    dentalObjectId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.OBJECT_LINKS, {
        params: {
          dental_object_id: dentalObjectId,
        },
      })
      .then((response) => {
        const objectLinks = deserialize(
          ObjectLink,
          response.data["object_links"]
        );
        onSuccess(objectLinks);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createObjectLink(
    objectLink: ObjectLink,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const objectLinkJSON = { object_link: serialize(objectLink) };
    axiosInstance
      .post(ApiRoutes.OBJECT_LINKS, objectLinkJSON)
      .then((response) => {
        Notification({
          message: "Object link created!",
          type: NotificationTypes.SUCCESS,
        });
        const objectLink = deserialize(
          ObjectLink,
          response.data["object_link"]
        );
        onSuccess(objectLink);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateObjectLink(
    objectLink: ObjectLink,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.OBJECT_LINKS + "/" + objectLink.id;
    const objectLinkJSON = { object_link: serialize(objectLink) };
    axiosInstance
      .put(API_URL, objectLinkJSON)
      .then((response) => {
        Notification({
          message: "Object link updated!",
          type: NotificationTypes.SUCCESS,
        });
        const objectLink = deserialize(
          ObjectLink,
          response.data["object_link"]
        );
        onSuccess(objectLink);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteObjectLink(
    objectLinkId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.OBJECT_LINKS + "/" + objectLinkId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        Notification({
          message: "Object link removed!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
