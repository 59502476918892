import React, { useEffect, useState } from "react";
import "./objectLinkPreview.scss";
import { Engine, Scene, SceneEventArgs } from "react-babylonjs";
import {
  AbstractMesh,
  ActionManager,
  ArcRotateCamera,
  Color3,
  HemisphericLight,
  Mesh,
  SceneLoader,
  StandardMaterial,
  Texture,
  Vector3,
} from "@babylonjs/core";
import { DentalObject } from "../../../models/DentalObject/dentalObject.model";
import { ObjectLocator } from "../../../models/ObjectLocator/objectLocator.model";

interface ObjectLinkPreviewProps {
  srcObject?: DentalObject;
  destObject?: DentalObject;
  srcLocator?: ObjectLocator;
  destLocator?: ObjectLocator;
}

function ObjectLinkPreview({
  srcObject,
  destObject,
  srcLocator,
  destLocator,
}: ObjectLinkPreviewProps) {
  const [scene, setScene] = useState();

  const handleSetLocators = (scene: any) => {
    if (
      srcObject?.name &&
      destObject?.name &&
      srcLocator?.name &&
      destLocator?.name
    ) {
      const sourceObject = scene.getMeshByName(srcObject.name);
      const sourceLocator = scene.getMeshByName(srcLocator.name);
      const destinationObject = scene.getMeshByName(destObject.name);
      const destinationLocator = scene.getMeshByName(destLocator.name);
      if (
        sourceObject &&
        sourceLocator &&
        destinationObject &&
        destinationLocator
      ) {
        destinationObject.parent = sourceLocator;
        destinationObject.position = new Vector3(
          -destinationLocator.position.x,
          -destinationLocator.position.y,
          -destinationLocator.position.z
        );
      }
    }
  };

  useEffect(() => {
    if (scene) {
      scene.meshes.map((mesh: Mesh) => mesh.dispose());
      if (srcObject?.attachmentUrl)
        SceneLoader.ImportMesh(
          null,
          srcObject?.attachmentUrl,
          "",
          scene,
          function (meshes, particleSystems, skeletons) {
            meshes.forEach((mesh) => {
              if (mesh.getChildren().length > 0) {
                mesh.position = new Vector3(0, 0, 0);
                mesh.actionManager = new ActionManager(scene);
                const material = new StandardMaterial("myMaterial", scene);
                material.diffuseTexture = new Texture(
                  "/teethTexture.jpg",
                  scene
                );
                mesh.material = material;
              }
            });
            if (destObject?.attachmentUrl && destObject?.name) {
              const mesh = scene.getMeshByName(destObject.name);
              if (mesh) {
                handleSetLocators(scene);
              } else {
                SceneLoader.ImportMesh(
                  null,
                  destObject?.attachmentUrl,
                  "",
                  scene,
                  function (meshes, particleSystems, skeletons) {
                    handleSetLocators(scene);
                  }
                );
              }
            }
          }
        );
    }
  }, [scene, srcObject, destObject, srcLocator, destLocator]);

  return (
    <div className="object-link-preview">
      <Engine
        antialias
        adaptToDeviceRatio
        canvasId="a4d-object-link"
        width={500}
      >
        <Scene
          onMeshPicked={(mesh: AbstractMesh, scene) => {}}
          onSceneMount={(e: SceneEventArgs) => {
            const { canvas, scene } = e;
            setScene(scene);
            const camera = new ArcRotateCamera(
              "Camera",
              -Math.PI / 2,
              Math.PI / 2,
              20,
              new Vector3(0, 0, 0),
              scene
            );

            camera.setTarget(Vector3.Zero());

            camera.attachControl(canvas, true);

            const light = new HemisphericLight(
              "light1",
              new Vector3(0, 1, 0),
              scene
            );

            light.intensity = 0.5;

            scene.getEngine().runRenderLoop(() => {
              if (scene) {
                scene.render();
              }
            });
          }}
        >
          <arcRotateCamera
            name="camera1"
            target={Vector3.Zero()}
            alpha={Math.PI / 2}
            beta={Math.PI / 4}
            radius={8}
          />
          <hemisphericLight
            name="light1"
            intensity={0.7}
            direction={Vector3.Up()}
          />
        </Scene>
      </Engine>
    </div>
  );
}

export default ObjectLinkPreview;
