import React, { useEffect, useState } from "react";
import { Engine, Scene, SceneEventArgs } from "react-babylonjs";
import {
  Vector3,
  Color3,
  ArcRotateCamera,
  HemisphericLight,
  SceneLoader,
  AbstractMesh,
  StandardMaterial,
  Texture,
  HighlightLayer,
  Mesh,
  ActionManager,
  ExecuteCodeAction,
} from "@babylonjs/core";
import "@babylonjs/loaders";
import "./dentalObjectPreview.scss";
import { ObjectLocator } from "../../../models/ObjectLocator/objectLocator.model";

interface DentalObjectPreviewProps {
  objectUrl?: string;
  setFieldValue?: Function;
}

function DentalObjectPreview({
  objectUrl,
  setFieldValue,
}: DentalObjectPreviewProps) {
  const [scene, setScene] = useState();

  useEffect(() => {
    if (scene && objectUrl) {
      scene.meshes.forEach((mesh: Mesh) => mesh.dispose());
      SceneLoader.ImportMesh(
        null,
        "",
        objectUrl,
        scene,
        function (meshes, particleSystems, skeletons) {
          const parentMeshes = meshes.filter(
            (mesh) => mesh.getChildren().length > 0
          );
          let locators: ObjectLocator[] = [];
          meshes.forEach((mesh) => {
            if (mesh.getChildren().length === 0) {
              locators.push(
                Object.assign(new ObjectLocator(), {
                  name: mesh.name,
                })
              );
            }
          });
          if (setFieldValue) {
            setFieldValue("objectLocators", locators);
          }
          if (parentMeshes && parentMeshes[0]) {
            const mesh = parentMeshes[0];
            if (setFieldValue) {
              setFieldValue("name", mesh.name);
            }
            mesh.position = new Vector3(0, 0, 0);
            mesh.actionManager = new ActionManager(scene);
            const material = new StandardMaterial("myMaterial", scene);
            material.diffuseTexture = new Texture("/teethTexture.jpg", scene);
            mesh.material = material;
          }
        }
      );
    }
  }, [objectUrl, scene]);

  return (
    <div className="dental-object-preview">
      <Engine
        antialias
        adaptToDeviceRatio
        canvasId="a4d-dental-object"
        width={500}
      >
        <Scene
          onMeshPicked={(mesh: AbstractMesh, scene) => {}}
          onSceneMount={(e: SceneEventArgs) => {
            const { canvas, scene } = e;
            setScene(scene);
            const camera = new ArcRotateCamera(
              "Camera",
              -Math.PI / 2,
              Math.PI / 2,
              20,
              new Vector3(0, 0, 0),
              scene
            );

            camera.setTarget(Vector3.Zero());

            camera.attachControl(canvas, true);

            const light = new HemisphericLight(
              "light1",
              new Vector3(0, 1, 0),
              scene
            );

            light.intensity = 0.5;

            scene.getEngine().runRenderLoop(() => {
              if (scene) {
                scene.render();
              }
            });
          }}
        >
          <arcRotateCamera
            name="camera1"
            target={Vector3.Zero()}
            alpha={Math.PI / 2}
            beta={Math.PI / 4}
            radius={8}
          />
          <hemisphericLight
            name="light1"
            intensity={0.7}
            direction={Vector3.Up()}
          />
        </Scene>
      </Engine>
    </div>
  );
}

export default DentalObjectPreview;
