import React, { useEffect } from "react";
import AuthContainer from "../../../store/container/AuthContainer";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { ResponsibilityTypes } from "../../../enums/responsibility.enum";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import RestrictedPage from "../../../views/RestrictedPage";

const requireAuth = (Component: any, permission?: ResponsibilityTypes) => {
  const Authentication = (props: AuthReducerProps) => {
    const { authenticated, userResponsibilities } = props;
    useEffect(() => {
      if (!authenticated) {
        window.location.href = AppRoutes.LOGIN;
      }
    }, [props]);
    
    if(!permission || userResponsibilities.includes(permission)) {
      return <Component {...props} />;
    }
    return <RestrictedPage />
  };
  return AuthContainer(Authentication);
};

export default requireAuth;
