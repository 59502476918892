
import {Role} from '../Role/role.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class Admin { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('first_name', primitive()))
	firstName?: string;

	@serializable(alias('last_name', primitive()))
	lastName?: string;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('profile_pic_url', primitive()))
	profilePicUrl?: string;

	@serializable(alias('role', object(Role)))
	role?: Role;

}