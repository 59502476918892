import { serialize, deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { User } from "../../models/User/user.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { convertJSONToFormData } from "../../shared/utils/dataFormatConverter";
export default class UserService {
  static async fetchUsers(
    onSuccess: (users: User[]) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.USERS);
      const users = deserialize(User, data?.admins as any[]);
      onSuccess(users);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async createUser(
    user: User,
    onSuccess: (user: User) => void,
    onFinal: () => void
  ) {
    const userJSON = {
      admin: serialize(user),
    };
    const FormData = convertJSONToFormData(userJSON);
    try {
      const { data } = await axiosInstance.post(ApiRoutes.USERS, FormData);
      const user = deserialize(User, data?.admin);
      if (user) {
        onSuccess(user);
      }
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async updateUser(
    user: User,
    onSuccess: (user: User) => void,
    onFinal: () => void
  ) {
    const userJSON = {
      admin: serialize(user),
    };
    const URL = ApiRoutes.USERS + "/" + user?.id;
    try {
      const { data } = await axiosInstance.put(URL, userJSON);
      const user = deserialize(User, data?.admin);
      if (user) {
        onSuccess(user);
      }
    } catch (error) {
      console.log(error?.message);
    } finally {
      onFinal();
    }
  }
}
