
import {serializable, alias, object, list, primitive} from 'serializr';

export class PaymentIntent { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('stripe_payment_intent_id', primitive()))
	stripePaymentIntentId?: string;

	@serializable(alias('stripe_payment_intent_status', primitive()))
	stripePaymentIntentStatus?: string;

	@serializable(alias('stripe_payment_intent_amount', primitive()))
	stripePaymentIntentAmount?: number;

	@serializable(alias('stripe_subscription_id', primitive()))
	stripeSubscriptionId?: string;

	@serializable(alias('stripe_user_type', primitive()))
	stripeUserType?: string;

	@serializable(alias('stripe_user_id', primitive()))
	stripeUserId?: string;

	@serializable(alias('contract_subscription_id', primitive()))
	contractSubscriptionId?: number;

	@serializable(alias('contract_patient_id', primitive()))
	contractPatientId?: number;

	@serializable(alias('contract_patient_first_name', primitive()))
	contractPatientFirstName?: string;

	@serializable(alias('contract_patient_last_name', primitive()))
	contractPatientLastName?: string;

	@serializable(alias('user_type', primitive()))
	userType?: string;

	@serializable(alias('user_id', primitive()))
	userId?: number;

	@serializable(alias('contract_id', primitive()))
	contractId?: number;

	@serializable(alias('corporate_id', primitive()))
	corporateId?: number;

	@serializable(alias('corporate_name', primitive()))
	corporateName?: string;

	@serializable(alias('practice_id', primitive()))
	practiceId?: number;

	@serializable(alias('practice_name', primitive()))
	practiceName?: string;

	@serializable(alias('created_at', primitive()))
	createdAt?: string;

	@serializable(alias('payment_type', primitive()))
	paymentType?: string;

	@serializable(alias('payment_date', primitive()))
	paymentDate?: string;

	@serializable(alias('amount', primitive()))
	amount?: string;

}