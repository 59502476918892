import { Button, Drawer, Table } from "antd";
import React from "react";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import "./subscriptionList.scss";
import { useState } from "react";
import { useEffect } from "react";
import SubscriptionService from "../../../services/Subscription/subscription.service";
import AppLoader from "../../../shared/components/AppLoader";
import SubscriptionForm from "../SubscriptionForm";
import Subscription from "../../../models/Subscription/subscription.model";
import ViewSubscription from "../ViewSubscription";
import SubscriptionFrequency from "../../../models/SubscriptionFrequency/subscriptionFrequency.model";
import { Tenure } from "../../../enums/tenure.enum";

interface SubscriptionListProps {}

const genSubscription = (subscription: Subscription) => {
  for (let frequency of subscription?.SubscriptionFrequency) {
    if (frequency.subscriptionType === Tenure.MONTHLY) {
      subscription.monthlyAmount = frequency.recurringAmount;
      subscription.tenure = [
        ...subscription.tenure,
        frequency.subscriptionType,
      ];
    }
    if (frequency.subscriptionType === Tenure.YEARLY) {
      subscription.yearlyAmount = frequency.recurringAmount;
      subscription.tenure = [
        ...subscription.tenure,
        frequency.subscriptionType,
      ];
    }
  }
  return subscription;
};

function SubscriptionList(props: SubscriptionListProps) {
  const [loading, setLoading] = useState(false);

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  const [currentSubscription, setCurrentSubscription] = useState<
    Subscription | boolean
  >(false);

  const [viewSubscription, setViewSubscription] = useState<Subscription>();

  useEffect(() => {
    setLoading(true);
    SubscriptionService.fetchSubscription(
      (subscriptions) => {
        setSubscriptions(
          subscriptions?.map((subscription) => genSubscription(subscription))
        );
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: "Subscription name",
      dataIndex: "subscriptionName",
      key: "subscriptionName",
    },
    {
      title: "Max no of practices",
      dataIndex: "maxNoOfPractices",
      key: "maxNoOfPractices",
    },
    {
      title: "Max no of staffs",
      dataIndex: "maxNoOfStaff",
      key: "maxNoOfStaff",
    },
    {
      title: "Max no of patients",
      dataIndex: "maxNoOfPatients",
      key: "maxNoOfPatients",
    },
    {
      title: "Monthly price",
      dataIndex: "monthlyAmount",
      key: "monthlyAmount",
      render: (amount: string) => {
        if (amount) {
          return `$${amount}`;
        }
        return "--";
      },
    },
    {
      title: "Yearly price",
      dataIndex: "yearlyAmount",
      key: "yearlyAmount",
      render: (amount: string) => {
        if (amount) {
          return `$${amount}`;
        }
        return "--";
      },
    },
    {
      title: "",
      dataIndex: "yearlyAmount",
      key: "yearlyAmount",
      render: (_: any, subscription: Subscription) => {
        return (
          <Button
            type="primary"
            className="btn-edit"
            onClick={(event) => {
              event?.stopPropagation();
              handleEditSubscription(subscription);
            }}
          >
            <EditOutlined />
          </Button>
        );
      },
    },
  ];

  const handleClose = () => {
    setCurrentSubscription(false);
  };

  const handleOpen = () => {
    setCurrentSubscription(true);
  };

  const handleCloseView = () => {
    setViewSubscription(undefined);
  };

  const handleRowClick = (subscription: Subscription) => {
    return {
      onClick: () => {
        setViewSubscription(subscription);
      },
    };
  };

  const handleEditSubscription = (subscription?: Subscription) => {
    if (subscription) {
      setViewSubscription(undefined);
      setTimeout(() => {
        setCurrentSubscription(subscription);
      }, 200);
    }
  };

  const handleSuccess = (subscription: Subscription) => {
    if (typeof currentSubscription === "boolean") {
      setSubscriptions((subscriptions) => [
        ...subscriptions,
        genSubscription(subscription),
      ]);
    } else {
      const index = subscriptions.findIndex(({ id }) => id === subscription.id);
      const updatedSubscriptions = [...subscriptions];
      updatedSubscriptions?.splice(index, 1, genSubscription(subscription));
      setSubscriptions(updatedSubscriptions);
    }
    setCurrentSubscription(false);
  };

  return (
    <div className="subscription-list">
      <div className="title">
        <h5 className="text-secondary">All Subscription</h5>
        <Button type="primary" onClick={handleOpen}>
          <PlusOutlined />
          Add Subscription
        </Button>
      </div>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Table
          dataSource={subscriptions}
          columns={columns}
          rowClassName="subscription__row"
          onRow={handleRowClick}
        />
      )}
      <Drawer
        visible={currentSubscription ? true : false}
        destroyOnClose
        onClose={handleClose}
        title={
          typeof currentSubscription === "boolean"
            ? "New Subscription"
            : "Edit Subscription"
        }
        width="65vw"
      >
        <SubscriptionForm
          subscription={currentSubscription}
          successHandler={handleSuccess}
        />
      </Drawer>
      <Drawer
        visible={viewSubscription ? true : false}
        destroyOnClose
        onClose={handleCloseView}
        title="View Subscription"
        width="65vw"
      >
        <ViewSubscription
          subscription={viewSubscription}
          editSubscriptionHandler={handleEditSubscription}
        />
      </Drawer>
    </div>
  );
}

export default SubscriptionList;
