import { serializable, alias, object, list, primitive } from "serializr";

export class ObjectLink {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("src_locator_name", primitive()))
  srcLocatorName?: string;

  @serializable(alias("src_locator_id", primitive()))
  srcLocatorId?: number;

  @serializable(alias("dest_locator_id", primitive()))
  destLocatorId?: number;

  destObjectType?: string;

  @serializable(alias("dest_locator_name", primitive()))
  destLocatorName?: string;

  @serializable(alias("src_object_id", primitive()))
  srcObjectId?: number;

  @serializable(alias("src_object_name", primitive()))
  srcObjectName?: string;

  @serializable(alias("dest_object_id", primitive()))
  destObjectId?: number;

  @serializable(alias("dest_object_name", primitive()))
  destObjectName?: string;
}
