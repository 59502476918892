import React from "react";
import {Button, Modal} from "antd";
import "./confirmationDialog.scss";

interface ConfirmDialogConfig {
    title: string;
    message: string;
    actionCallback?: Function;
}

const ConfirmationDialog = ({ open, title, message, onConfirm, onDismiss }: any) => {
    return (
        <Modal
            className='confirmation-dialog'
            footer=''
            visible={open}
            onOk={onConfirm}
            onCancel={onDismiss}>
            <h5> {title} </h5>
            <div className='text-right'>
                <Button className='mr-2' onClick={onDismiss}>Cancel</Button>
                <Button type="primary" onClick={onConfirm}>
                    Confirm
                </Button>
            </div>
        </Modal>
    );
};

const ConfirmationDialogContext = React.createContext<any>({});

const ConfirmationDialogProvider = ({ children }: any) => {

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [dialogConfig, setDialogConfig] = React.useState<ConfirmDialogConfig>({
        title: 'string',
        message: 'string'
    });

    const openDialog = ({ title, message, actionCallback }: any) => {
        setDialogOpen(true);
        setDialogConfig({ title, message, actionCallback });
    };

    const resetDialog = () => {
        setDialogOpen(false);
        setDialogConfig({
            title: '',
            message: ''
        });
    };

    const onConfirm = () => {
        resetDialog();
        if (dialogConfig.actionCallback) {
            dialogConfig.actionCallback(true);
        }
    };

    const onDismiss = () => {
        resetDialog();
        if (dialogConfig.actionCallback) {
            dialogConfig.actionCallback(false);
        }
    };

    return (
        <ConfirmationDialogContext.Provider value={{ openDialog }}>
            <ConfirmationDialog
                open={dialogOpen}
                title={dialogConfig?.title}
                message={dialogConfig?.message}
                onConfirm={onConfirm}
                onDismiss={onDismiss}
            />
            {children}
        </ConfirmationDialogContext.Provider>
    );
};

const useConfirmationDialog = () => {
    const { openDialog } = React.useContext(ConfirmationDialogContext);

    const getConfirmation = ({ ...options }) =>
        new Promise((res) => {
            openDialog({ actionCallback: res, ...options });
        });

    return { getConfirmation };
};

export default ConfirmationDialog;
export { ConfirmationDialogProvider, useConfirmationDialog };
