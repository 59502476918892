import * as Yup from "yup";
import {
  validateNumber,
  validateString,
} from "../../../shared/utils/validatorUtils";

export const objectLinkFormValidation = Yup.object().shape({
  srcLocatorId: validateNumber("Locator is required", true),
  destLocatorId: validateNumber("Locator is required", true),
});
