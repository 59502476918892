import { Admin } from "../../models/Admin/admin.model";
import {  SET_USER, UNAUTHENTICATED } from "../definitions/authConstants";

export const setUnAuthenticated = () => {
    return {
        type: UNAUTHENTICATED,
        payload: {
            authenticated: false,
            user: null
        }
    }
}

export const setUser = (user: Admin) => {
    return {
        type: SET_USER,
        payload: {
            user
        }
    }
}