import React, { useEffect, useState } from "react";
import "./objectLinkForm.scss";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Row } from "antd";
import { Form, Formik } from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import { ObjectLink } from "../../../models/ObjectLink/objectLink.model";
import RadioInputField from "../../../shared/components/RadioInputField";
import ObjectLinkPreview from "../ObjectLinkPreview";
import { DentalObject } from "../../../models/DentalObject/dentalObject.model";
import DentalObjectService from "../../../services/DentalObject/dentalObject.service";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import { objectLinkFormValidation } from "./objectLinkFormValidation";
import { getDropdownOptions } from "../../../shared/utils/optionsUtils";
import ObjectLinkService from "../../../services/ObjectLink/objectLink.service";
import ObjectLinksCard from "../ObjectLinksCard";
import { ObjectLocator } from "../../../models/ObjectLocator/objectLocator.model";

interface ObjectLinkFormProps {}

interface ObjectLinkParamsProps {
  dentalObjectId?: string;
}

function ObjectLinkForm(props: ObjectLinkFormProps) {
  const [srcObject, setSrcObject] = useState<DentalObject>();

  const [refreshLinks, setRefreshLinks] = useState(true);

  const [destObject, setDestObject] = useState<DentalObject>();

  const [srcLocator, setSrcLocator] = useState<ObjectLocator>();

  const [destLocator, setDestLocator] = useState<ObjectLocator>();

  const [srcLocatorOptions, setSrcLocatorOptions] = useState<
    IDropdownOptions[]
  >([]);

  const [destModels, setDestModels] = useState<DentalObject[]>([]);

  const [destModelOptions, setDestModelOptions] = useState<IDropdownOptions[]>(
    []
  );

  const [destLocatorOptions, setDestLocatorOptions] = useState<
    IDropdownOptions[]
  >([]);

  const history = useHistory();

  const params: ObjectLinkParamsProps = useParams();

  const [formLoading, setFormLoading] = useState(false);

  const [formValues, setFormValues] = useState(new ObjectLink());

  const objectTypes = [
    { label: "Anatomy", value: "anatomy" },
    { label: "Vendor", value: "vendor" },
  ];

  const handleRefreshComplete = () => setRefreshLinks(false);

  const handleSetDestinationObject = (destObjectId: number) => {
    DentalObjectService.showDentalObject(
      destObjectId,
      (destObject: DentalObject) => {
        if (destObject) {
          setDestObject(destObject);
          if (destObject.objectLocators)
            setDestLocatorOptions(
              getDropdownOptions(destObject.objectLocators, "name", "id")
            );
        }
      },
      () => {},
      () => {}
    );
  };

  const handleSubmit = (values: ObjectLink) => {
    const objectLink = Object.assign(new ObjectLink(), values);
    setFormLoading(true);
    ObjectLinkService.createObjectLink(
      objectLink,
      (objectLink: ObjectLink) => {
        debugger;
        setRefreshLinks(true);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const handleFetchDestinationObjects = (objectType: string) => {
    DentalObjectService.fetchDentalObjects(
      objectType,
      (dentalObjects: DentalObject[]) => {
        setDestModels(dentalObjects);
        setDestModelOptions(getDropdownOptions(dentalObjects, "name", "id"));
      },
      () => {},
      () => {}
    );
  };

  const handleCancel = () => history.goBack();

  useEffect(() => {
    if (params.dentalObjectId) {
      DentalObjectService.showDentalObject(
        Number(params.dentalObjectId),
        (dentalObject: DentalObject) => {
          setSrcObject(dentalObject);
          if (dentalObject && dentalObject.objectLocators) {
            setSrcLocatorOptions(
              getDropdownOptions(dentalObject.objectLocators, "name", "id")
            );
          }
        },
        () => {},
        () => {}
      );
    }
  }, []);

  return (
    <div className="object-link-form">
      <Row>
        <Col span={12}>
          <h5> Link Objects</h5>
        </Col>
        <Col span={12} className="text-right">
          <Button type="default" onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
      <Row gutter={[20, 0]} className="mt-5">
        <Col span={16}>
          <div className="object-link-form__model">
            <ObjectLinkPreview
              srcObject={srcObject}
              destObject={destObject}
              srcLocator={srcLocator}
              destLocator={destLocator}
            />
          </div>
        </Col>
        <Col span={8} className="object-link-form__form-wrapper">
          <div>
            <Formik
              initialValues={formValues}
              onSubmit={handleSubmit}
              enableReinitialize
              validationSchema={objectLinkFormValidation}
            >
              {({ values, errors, isValid, dirty, setFieldValue }) => {
                return (
                  <Form>
                    <Row gutter={[20, 0]}>
                      <div className="object-link-form__form">
                        <Col span={24}>
                          <h5>Current Object - {srcObject?.name}</h5>
                          <DropdownField
                            title="Current Locator"
                            showSearch
                            placeHolder="Select Locator"
                            name="srcLocatorId"
                            onChange={(value) => {
                              setFieldValue("srcLocatorId", value);
                              const locator = srcLocatorOptions.find(
                                (option) => option.value === value
                              );
                              if (locator) {
                                setSrcLocator(
                                  Object.assign(new ObjectLocator(), {
                                    id: locator.value,
                                    name: locator.label,
                                  })
                                );
                              }
                            }}
                            options={srcLocatorOptions}
                            value={values.srcLocatorId}
                          />
                        </Col>
                      </div>
                      <div className="object-link-form__form">
                        <Col span={24}>
                          <h5>Target Object</h5>
                          <RadioInputField
                            name="destObjectType"
                            onChange={(e) => {
                              setFieldValue("destObjectType", e.target.value);
                              handleFetchDestinationObjects(e.target.value);
                            }}
                            options={objectTypes}
                            value={values.destObjectType}
                          />
                        </Col>
                        <Col span={24}>
                          <DropdownField
                            title="Model name"
                            showSearch
                            placeHolder="Select Model"
                            name="destObjectId"
                            onChange={(value) => {
                              setFieldValue("destObjectId", value);
                              handleSetDestinationObject(value);
                            }}
                            options={destModelOptions}
                            value={values.destObjectId}
                          />
                        </Col>
                        <Col span={24}>
                          <DropdownField
                            title="Locator"
                            name="destLocatorId"
                            showSearch
                            placeHolder="Select Target Locator"
                            onChange={(value) => {
                              setFieldValue("destLocatorId", value);
                              const locator = destLocatorOptions.find(
                                (option) => option.value === value
                              );
                              if (locator) {
                                setDestLocator(
                                  Object.assign(new ObjectLocator(), {
                                    id: locator.value,
                                    name: locator.label,
                                  })
                                );
                              }
                            }}
                            options={destLocatorOptions}
                            value={values.destLocatorId}
                          />
                        </Col>
                        <Col span={24} className="mt-3 text-right">
                          <Button
                            type="primary"
                            className="ml-2"
                            htmlType="submit"
                            loading={formLoading}
                            disabled={!isValid || !dirty || formLoading}
                          >
                            Create Link
                          </Button>
                        </Col>
                      </div>
                      <div className="object-link-form__form">
                        <div className="object-link-form__locators">
                          {srcObject?.id && (
                            <ObjectLinksCard
                              dentalObjectId={srcObject?.id}
                              refreshLinks={refreshLinks}
                              onRefreshComplete={handleRefreshComplete}
                            />
                          )}
                        </div>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ObjectLinkForm;
