import { IDropdownOptions } from "../../services/Meta/meta.service";

export const getDropdownOptions = (
  array: any[],
  labelKey: string,
  valueKey: string
): IDropdownOptions[] => {
  return array.map((object) => ({
    label: object[labelKey],
    value: object[valueKey],
  }));
};
