import { Col, Row } from "antd";
import React, { FC, useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Corporate } from "../../../../models/Corporate/corporate.model";
import CorporateService from "../../../../services/Corporate/corporate.service";
import AppLoader from "../../../../shared/components/AppLoader";
import "./corporateInformation.scss";

interface ParamsProps {
  corporateId: string;
}

interface CorporateInformationProps {}

const CorporateInformation: FC<CorporateInformationProps> = (props) => {
  const {} = props;

  const params = useParams<ParamsProps>();

  const [corporate, setCorporate] = useState<Corporate>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    CorporateService.showCorporate(
      params.corporateId,
      (corporate: Corporate) => {
        setCorporate(corporate);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  if(loading) {
      return <AppLoader loading/>
  }

  return (
    <Row className="corporate-information">
      <Col span={12}>
        <label htmlFor="">Corporate Name</label>
        <p>{corporate?.name}</p>
      </Col>
      <Col span={12}>
        <label htmlFor="">Subscription Plan</label>
        <p>{corporate?.subscriptionName}</p>
      </Col>
      <Col span={12}>
        <label htmlFor="">Email</label>
        <p>{corporate?.primaryEmail}</p>
      </Col>
      <Col span={12}>
        <label htmlFor="">Mobile</label>
        <p>{corporate?.mobileNumber}</p>
      </Col>
      <Col span={12}>
        <label htmlFor="">No of Practices</label>
        <p>{corporate?.noOfPractices}</p>
      </Col>
      <Col span={12}>
        <label htmlFor="">No of Staff</label>
        <p>{corporate?.noOfStaffs}</p>
      </Col>
      <Col span={12}>
        <label htmlFor="">Website</label>
        <a className="link" href={corporate?.websiteUrl} target="_blank">
          {corporate?.websiteUrl}
        </a>
      </Col>
      <Col span={12}>
        <label htmlFor="">Facebook</label>
        <a className="link" href={corporate?.facebookUrl} target="_blank">
          {corporate?.facebookUrl}
        </a>
      </Col>
      <Col span={12}>
        <label htmlFor="">Status</label>
        <p>{corporate?.status}</p>
      </Col>
    </Row>
  );
};

export default CorporateInformation;
