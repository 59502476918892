import { Button, Drawer, Table } from "antd";
import React, { FC } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Support as SupportModel } from "../../models/Support/support.model";
import Stats from "../../shared/components/Stats";
import moment from "moment";
import "./support.scss";
import SupportDetails from "./SupportDetails";
import SupportService from "../../services/Support/support.service";
import { SupportStatus } from "../../enums/supportStatus.enum";
import Assign from "./Assign";
import { StatsTypeEnum } from "../../enums/statsType.enum";
import MetricsService from "../../services/Metrics/metrics.service";
import { useParams } from "react-router-dom";
import { Metrics } from "../../models/Metrics/metrics.model";
import AppLoader from "../../shared/components/AppLoader";

interface SupportProps {}

const Support: FC<SupportProps> = (props) => {
  const {} = props;

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Issue Type",
      dataIndex: "issueType",
      key: "issueType",
    },
    {
      title: "Issue Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Raised By",
      dataIndex: "raisedBy",
      key: "raisedBy",
      render: (_: any, support: SupportModel) => {
        return support.raisedByFirstName + " " + support.raisedByLastName;
      },
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "assignedTo",
      render: (name: string, support: SupportModel, index: number) => (
        <span>
          {support?.assignedToAdminId ? (
            support.assignedToFirstName + " " + support.assignedToLastName
          ) : (
            <Assign
              support={support}
              onCloseHandler={handleUpdateSupportTicket}
            />
          )}
        </span>
      ),
    },
    {
      title: "Raised Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (isoString: string) => (
        <span>{moment(isoString).format("MM/DD/YYYY")}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: SupportStatus) => (
        <span
          className={
            status === SupportStatus.PENDING ? "text-warning" : "text-success"
          }
        >
          {status?.toUpperCase()}
        </span>
      ),
    },
  ];

  const [supportTickets, setSupportTickets] = useState<SupportModel[]>([]);

  const [supportTicket, setSupportTicket] = useState<SupportModel>();
  const [metrics, setMetrics] = useState<Metrics>();
  const params = useParams<{ corporateId: string }>();

  useEffect(() => {
    setLoading(true);
    SupportService.fetchSubscription(
      (supports) => {
        setSupportTickets(supports);
      },
      () => {
        setLoading(false);
      }
    );

    MetricsService.fetchCorporateMetrics(
      "issues",
      (metrics: Metrics) => {
        setMetrics(metrics);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const handleOpenTicketDetail = (supportTicket: SupportModel) =>
    setSupportTicket(supportTicket);

  const handleUpdateSupportTicket = (support: SupportModel) => {
    const index = supportTickets.findIndex(({ id }) => id === support?.id);
    const updatedSupports = [...supportTickets];
    updatedSupports.splice(index, 1, support);
    setSupportTickets(updatedSupports);
    handleCloseTicketDetail();
  };

  const handleCloseTicketDetail = () => {
    setSupportTicket(undefined);
  };

  const handleViewIssue = (supportTicket: SupportModel) => ({
    onClick: () => {
      setTimeout(() => {
        handleOpenTicketDetail(supportTicket);
      }, 200);
    },
  });
  const statsData = [
    {
      title: "Total issues",
      value: metrics?.totalIssues ?? 0,
    },
    {
      title: "Resolved Issues",
      value: metrics?.resolvedIssues ?? 0,
    },
    {
      title: "Pending Issues",
      value: metrics?.pendingIssues ?? 0,
    },
  ];

  return (
    <div className="support">
      <div className="header">
        <p className="text-secondary">Corporate issues</p>
      </div>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <>
          <Stats statsData={statsData} />
          <Table
            columns={columns}
            dataSource={supportTickets}
            onRow={handleViewIssue}
            loading={loading}
          />
        </>
      )}
      <Drawer
        title="View Issue"
        visible={supportTicket ? true : false}
        onClose={handleCloseTicketDetail}
        destroyOnClose
        width="65vw"
      >
        <SupportDetails
          supportTicket={supportTicket}
          closeHandler={handleUpdateSupportTicket}
        />
      </Drawer>
    </div>
  );
};

export default Support;
