import React from "react";
import {ErrorMessage, Field} from "formik";
import {Checkbox} from "antd";
import Error from "../Error";
import {CheckboxChangeEvent} from "antd/es/checkbox";

interface CheckboxFieldProps {
    name: string;
    text: string;
    value?: boolean;
    setFieldValue: Function;
    onChange?: () => void;
}

function CheckboxField(props: CheckboxFieldProps) {

    const {name, text, value, setFieldValue, onChange} = props;

    const handleChange = (e: CheckboxChangeEvent) => {
        if (setFieldValue)
            setFieldValue(name, e.target.checked);
    };

    return (
        <Field name={name}>
            {({ field, form: { touched, errors }, meta }: any) => (
                <div className='checkbox-field'>
                    <Checkbox checked={value} onChange={onChange || handleChange}>
                       {text}
                    </Checkbox>
                    <ErrorMessage name={name}>
                        {(message: string) => <Error message={message} />}
                    </ErrorMessage>
                </div>
            )}
        </Field>
    );
}

export default CheckboxField;
