import React, { useEffect, useState } from "react";
import "./corporateList.scss";
import CorporateService from "../../../services/Corporate/corporate.service";
import { Corporate } from "../../../models/Corporate/corporate.model";
import { Button, Table } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { CorporateStatusEnum } from "../../../enums/corporateStatus.enum";
import AppLoader from "../AppLoader";

interface CorporateListProps {
  status: CorporateStatusEnum;
}

function CorporateList(props: CorporateListProps) {
  const { status } = props;

  const history = useHistory();

  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [corporates, setCorporates] = useState<Corporate[]>([]);

  const handleNavigateCorporate = (corporateId: string) => {
    history.push(
      generatePath(AppRoutes.PENDING_CORPORATE_DETAIL, {
        corporateId,
      })
    );
  };

  useEffect(() => {
    setLoading(true);
    CorporateService.fetchCorporates(
      status,
      (corporates: Corporate[]) => {
        setCorporates(corporates);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: "Corporation name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "primaryEmail",
      key: "primaryEmail",
    },
    {
      title: "Mobile",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      render: (text: string, record: any, index: number) => {
        return (
          <div>
            {" "}
            ({record?.isdCode}) {record?.mobileNumber}{" "}
          </div>
        );
      },
    },
    {
      title: "Practices",
      dataIndex: "noOfPractices",
      key: "noOfPractices",
    },
    {
      title: "Contact Person",
      dataIndex: "contactPerson",
      key: "contactPerson",
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
      render: (text: string, record: any, index: number) => {
        return status === CorporateStatusEnum.PENDING ? (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleNavigateCorporate(record?.id);
            }}
            className="bg-primary"
            icon={<EyeOutlined />}
          />
        ) : status === CorporateStatusEnum.REJECTED ? (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              handleNavigateCorporate(record?.id);
            }}
            className="warning-btn"
          >
            Pending
          </Button>
        ) : text ? (
          <span className="text-primary">Active</span>
        ) : (
          <span className="text-danger">'Inactive'</span>
        );
      },
    },
  ];

  const handleOpenCorporateDetail = (corporate: Corporate) => {
    return {
      onClick: () => {
        if (location.pathname === AppRoutes.CORPORATES) {
          history.push(
            generatePath(AppRoutes.CORPORATE_DETAIL, {
              corporateId: corporate?.id,
            })
          );
        }
      },
    };
  };

  return (
    <div className="corporate-list">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Table
          dataSource={corporates}
          columns={columns}
          onRow={handleOpenCorporateDetail}
          rowClassName="cursor-pointer"
        />
      )}
    </div>
  );
}

export default CorporateList;
