import {
  AUTHENTICATED,
  SET_USER,
  UNAUTHENTICATED,
} from "../definitions/authConstants";
import { CreateReducer } from "../../shared/utils/createReducer";
import { Admin } from "../../models/Admin/admin.model";

const userResponsibilities = [
  "corporate_approval_create",
  "corporate_approval_view",
  "corporate_approval_edit",
  "corporate_approval_delete",
  "corporate_create",
  "corporate_view",
  "corporate_edit",
  "corporate_delete",
  "dental_object_create",
  "dental_object_view",
  "dental_object_edit",
  "dental_object_delete",
  "link_create",
  "link_view",
  "link_edit",
  "link_delete",
  "user_create",
  "user_view",
  "user_edit",
  "user_delete",
  "role_create",
  "role_view",
  "role_edit",
  "role_delete",
];

interface IAuthState {
  authenticated: boolean;
  user?: Admin | null;
  userResponsibilities: string[];
}

export interface AuthReducerProps extends IAuthState {
  setUser: (user: Admin) => void;
}

const initState: IAuthState = {
  authenticated: false,
  user: null,
  userResponsibilities,
};

const authReducer = CreateReducer(initState, {
  [AUTHENTICATED](state: any, action: any) {
    const { authenticated, user } = action.payload;
    return {
      ...state,
      authenticated,
      user,
    };
  },
  [UNAUTHENTICATED](state: any, payload: any) {
    const { authenticated } = payload;
    return { ...state, authenticated };
  },

  [SET_USER](state: IAuthState, action: any): IAuthState {
    const { user }: { user: Admin } = action.payload;

    return {
      ...state,
      user,
      userResponsibilities: user.role?.permissions ?? [],
    };
  },
});

export default authReducer;
