import React from "react";
import { ErrorMessage, Field } from "formik";
import { Radio } from "antd";
import Error from "../Error";
import { IDropdownOptions } from "../../../services/Meta/meta.service";
import "./radioInputField.scss";
import { RadioChangeEvent } from "antd/es/radio";

interface RadioInputFieldProps {
  name: string;
  title?: string;
  options: IDropdownOptions[];
  setFieldValue?: Function;
  onChange?: (e: RadioChangeEvent) => void;
  value?: string;
}

function RadioInputField({
  name,
  title,
  onChange,
  options,
  value,
  setFieldValue,
}: RadioInputFieldProps) {
  const handleChange = (e: RadioChangeEvent) => {
    if (setFieldValue) setFieldValue(name, e.target.value);
  };

  return (
    <Field name={name}>
      {({ field, form: { touched, errors, setFieldTouched }, meta }: any) => {
        return (
          <div className="radio-input-field">
            {title && <div className="radio-input-field__title">{title}</div>}
            <Radio.Group onChange={onChange || handleChange} value={value}>
              {options.map((option, i) => (
                <Radio value={option.value} key={i}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
            <ErrorMessage name={name}>
              {(message: string) => (
                <Error className={`${name}__error`} message={message} />
              )}
            </ErrorMessage>
          </div>
        );
      }}
    </Field>
  );
}

export default RadioInputField;
