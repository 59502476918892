export enum ResponsibilityTypes {
  CORPORATE_APPROVAL_CREATE = "corporate_approval_create",
  CORPORATE_APPROVAL_VIEW = "corporate_approval_view",
  CORPORATE_APPROVAL_EDIT = "corporate_approval_edit",
  CORPORATE_APPROVAL_DELETE = "corporate_approval_delete",
  CORPORATE_CREATE = "corporate_create",
  CORPORATE_VIEW = "corporate_view",
  CORPORATE_EDIT = "corporate_edit",
  CORPORATE_DELETE = "corporate_delete",
  DENTAL_OBJECT_CREATE = "dental_object_create",
  DENTAL_OBJECT_VIEW = "dental_object_view",
  DENTAL_OBJECT_EDIT = "dental_object_edit",
  DENTAL_OBJECT_DELETE = "dental_object_delete",
  LINK_CREATE = "link_create",
  LINK_VIEW = "link_view",
  LINK_EDIT = "link_edit",
  LINK_DELETE = "link_delete",
  USER_CREATE = "user_create",
  USER_VIEW = "user_view",
  USER_EDIT = "user_edit",
  USER_DELETE = "user_delete",
  ROLE_CREATE = "role_create",
  ROLE_VIEW = "role_view",
  ROLE_EDIT = "role_edit",
  ROLE_DELETE = "role_delete",
}
