import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ResponsibilityTypes } from "../../enums/responsibility.enum";
import { AppRoutes } from "../../routes/routeConstants/appRoutes";
import RolesAndResponsibility from "./RolesAndResponsibility";
import requiredAuth from "../../shared/components/HOC/requireAuth";
import "./settings.scss";
import Users from "./Users";

interface SettingsProps {}

const isAuthenticated = (component: any, permission?: ResponsibilityTypes) => {
  return requiredAuth(component, permission);
};

const routes = [
  {
    exact: true,
    path: AppRoutes.USERS,
    component: isAuthenticated(Users, ResponsibilityTypes.USER_VIEW),
    title: "Users",
  },
  {
    exact: true,
    path: AppRoutes.ROLES_AND_PERMISSIONS,
    component: isAuthenticated(
      RolesAndResponsibility,
      ResponsibilityTypes.ROLE_VIEW
    ),
    title: "Roles And Responsibility",
  },
];

function Settings(props: SettingsProps) {
  return (
    <div className="settings">
      <Switch>
        {routes.map((route) => (
          <Route {...route} component={route.component} />
        ))}
        <Redirect from={AppRoutes.SETTINGS} to={AppRoutes.USERS} />
      </Switch>
    </div>
  );
}

export default Settings;
