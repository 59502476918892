import React from "react";
import "./xdAnalytics.scss";

interface XDAnalyticsProps {

}

function XDAnalytics(props: XDAnalyticsProps) {
    return (
        <div className='xd-analytics'>
            XDAnalytics works!
        </div>
    );
}

export default XDAnalytics;
