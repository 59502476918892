import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "antd";
import { ObjectLink } from "../../../models/ObjectLink/objectLink.model";
import ObjectLinkService from "../../../services/ObjectLink/objectLink.service";
import "./objectLinksCard.scss";
import { DeleteOutlined } from "@ant-design/icons";

interface ObjectLinksCardProps {
  dentalObjectId: number;
  refreshLinks: boolean;
  onRefreshComplete: () => void;
}

function ObjectLinksCard({
  dentalObjectId,
  refreshLinks,
  onRefreshComplete,
}: ObjectLinksCardProps) {
  const [objectLinks, setObjectLinks] = useState<ObjectLink[]>([]);

  const handleDelete = (objectLinkId?: number) => () => {
    if (objectLinkId)
      Modal.confirm({
        title: "Confirm",
        icon: null,
        content: "Are you sure want to delete the link?",
        onOk: () => {
          ObjectLinkService.deleteObjectLink(
            objectLinkId,
            () => {
              const objectLinkIndex = objectLinks.findIndex(
                (link) => link.id === objectLinkId
              );
              if (objectLinkIndex >= 0) objectLinks.splice(objectLinkIndex, 1);
              setObjectLinks([...objectLinks]);
            },
            () => {},
            () => {}
          );
        },
      });
  };

  useEffect(() => {
    if (dentalObjectId && refreshLinks) {
      ObjectLinkService.fetchObjectLinks(
        dentalObjectId,
        (objectLinks: ObjectLink[]) => {
          setObjectLinks(objectLinks);
          onRefreshComplete();
        },
        () => {},
        () => {}
      );
    }
  }, [dentalObjectId, refreshLinks]);
  return (
    <div className="object-links-card">
      <div className="object-links-card__locators">
        {objectLinks.map((objectLink) => (
          <Row
            gutter={[10, 0]}
            key={objectLink.id}
            align="middle"
            className="object-links-card__locator-row"
          >
            <Col span={22}>
              {objectLink?.srcObjectName} {objectLink?.srcLocatorName} -{" "}
              {objectLink?.destObjectName} {objectLink?.destLocatorName}
            </Col>
            <Col span={2}>
              <DeleteOutlined onClick={handleDelete(objectLink.id)} />
            </Col>
          </Row>
        ))}
      </div>
    </div>
  );
}

export default ObjectLinksCard;
