import React, {useRef, useState} from "react";
import "./corporateVerificationForm.scss";
import {Corporate} from "../../../models/Corporate/corporate.model";
import {Form, Formik, FormikValues} from "formik";
import {Button} from "antd";
import CheckboxField from "../../../shared/components/CheckboxField";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import InputField from "../../../shared/components/InputField";
import CorporateService from "../../../services/Corporate/corporate.service";
import {CorporateVerificationEnum} from "../../../enums/corporateVerification.enum";
import {useConfirmationDialog} from "../../../shared/components/HOC/ConfirmationDialog";
import {useHistory} from "react-router-dom";
import {AppRoutes} from "../../../routes/routeConstants/appRoutes";

interface CorporateVerificationFormProps {
    onCancel: Function;
    corporateId: string;
}

function CorporateVerificationForm(props: CorporateVerificationFormProps) {

    const history = useHistory();

    const {onCancel, corporateId} = props;

    const { getConfirmation } = useConfirmationDialog();

    const formRef: any = useRef();

    const [showRejectDetails, setShowRejectDetails] = useState(false);

    const handleShowRejectDetails = () => setShowRejectDetails(true);

    const handleHideRejectDetails = () => setShowRejectDetails(false);

    const handleSubmit = (values: FormikValues) => {};

    const handleVerifyCorporate = (status: CorporateVerificationEnum) => () => {
        const corporate = Object.assign(new Corporate(), {
            ...formRef.current.values,
            id: corporateId
        });
        getConfirmation({
            title: `Are you sure want to ${status} the corporate?`,
        }).then((res) => {
            if (res) {
                CorporateService.verifyCorporate(status, corporate, () => {
                    history.push(AppRoutes.HOME);
                }, () => {}, () => {})
            }
        });
    };

    return (
        <div className='corporate-verification-form'>
            <Formik
                initialValues={new Corporate()}
                innerRef={formRef}
                onSubmit={handleSubmit}>
                {({ handleSubmit, handleChange, values, errors, isValid, dirty, setFieldValue }) => {
                    return (
                        <Form>
                            <div className='corporate-verification-form__body'>
                                <CheckboxField
                                    name='businessLicenseValidity'
                                    text='Business license validity'
                                    value={values.businessLicenseValidity}
                                    setFieldValue={setFieldValue}/>
                                <CheckboxField
                                    name='socialPresence'
                                    text='Social presence'
                                    value={values.socialPresence}
                                    setFieldValue={setFieldValue}/>
                                <CheckboxField
                                    name='physicalPresence'
                                    text='Physical presence of practice'
                                    value={values.physicalPresence}
                                    setFieldValue={setFieldValue}/>
                                {
                                    showRejectDetails &&
                                    <InputField
                                        rows={3}
                                        title='Reject Reason'
                                        type='textArea'
                                        name="rejectReason"
                                        placeholder="Enter Reject Reason" />
                                }
                            </div>
                            <div className='mt-5 text-right'>
                                {
                                    showRejectDetails ?
                                        <>
                                            <Button
                                                onClick={handleHideRejectDetails}
                                                className='mr-3'>
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={handleVerifyCorporate(CorporateVerificationEnum.REJECT)}
                                                className='danger-btn mr-3'>
                                                <CloseOutlined/>
                                                Reject
                                            </Button>
                                        </> :
                                        <>
                                            <Button
                                                onClick={handleShowRejectDetails}
                                                className='danger-btn mr-3'>
                                                <CloseOutlined/>
                                                Reject
                                            </Button>
                                            <Button
                                                type='primary'
                                                onClick={handleVerifyCorporate(CorporateVerificationEnum.APPROVE)}>
                                                <CheckOutlined/>
                                                Approve
                                            </Button>
                                        </>
                                }
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

export default CorporateVerificationForm;
