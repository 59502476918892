import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";

import { deserialize, serialize } from "serializr";
import { Metrics } from "../../models/Metrics/metrics.model";
export default class MetricsService {
  static fetchMetrics(
    corporateId: string,
    metricsType: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(
        ApiRoutes.METRICS.replace(":metricsType", metricsType).replace(
          ":corporateId",
          corporateId
        )
      )
      .then((response) => {
        const metrics = deserialize(Metrics, response.data);
        onSuccess(metrics);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchCorporateMetrics(
    metricsType: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.CORPORATE_METRICS.replace(":metricsType", metricsType))
      .then((response) => {
        const metrics = deserialize(Metrics, response.data);
        onSuccess(metrics);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
