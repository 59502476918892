import { serializable, alias, primitive, list, object } from "serializr";
import { Tenure } from "../../enums/tenure.enum";
import SubscriptionFrequency  from "../SubscriptionFrequency/subscriptionFrequency.model";

export default class Subscription {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  subscriptionName?: string;

  @serializable(alias("max_no_of_patients", primitive()))
  maxNoOfPatients?: number;

  @serializable(alias("max_no_of_practices", primitive()))
  maxNoOfPractices?: number;

  @serializable(alias("max_no_of_staffs", primitive()))
  maxNoOfStaff?: number;

  @serializable(alias("tenure", list(primitive())))
  tenure: Tenure[] = [];

  @serializable(alias("is_enabled", primitive()))
  isEnabled: boolean = false;

  @serializable(alias("description", primitive()))
  featureDescription?: string;

  @serializable(alias("monthlySubscriptionAmount", primitive()))
  monthlyAmount?: string;

  @serializable(alias("yearlySubscriptionAmount", primitive()))
  yearlyAmount?: string;

  @serializable(alias('trial_period', primitive()))
	trialPeriod?: string;

  @serializable(
    alias("subscription_frequencies", list(object(SubscriptionFrequency)))
  )
  SubscriptionFrequency: SubscriptionFrequency[] = [];
}
