import { serializable, alias, object, list, primitive } from "serializr";

export class Metrics {
  /* issue Metrics */
  @serializable(alias("total_issues", primitive()))
  totalIssues?: number;

  @serializable(alias("resolved_issues", primitive()))
  resolvedIssues?: number;

  @serializable(alias("pending_issues", primitive()))
  pendingIssues?: number;

  /* Patient Metrics */
  @serializable(alias("total_no_of_patients", primitive()))
  totalNoOfPatients?: number;

  @serializable(alias("active_patients", primitive()))
  activePatients?: number;

  @serializable(alias("patients_without_future_appointments", primitive()))
  patientsWithoutFutureAppointments?: number;

  @serializable(alias("inactive_patients", primitive()))
  inactivePatients?: number;

  /* Practices Metrics */

  @serializable(alias("total_no_of_practices", primitive()))
  totalNoOfPractices?: number;

  @serializable(alias("active_practices", primitive()))
  activePractices?: number;

  @serializable(alias("inactive_practices", primitive()))
  inactivePractices?: number;

  /* Staff Metrics */

  @serializable(alias("total_staffs", primitive()))
  totalStaffs?: number;

  @serializable(alias("no_of_doctors", primitive()))
  noOfDoctors?: number;

  @serializable(alias("no_of_assistant", primitive()))
  noOfAssistant?: number;

  /* Appointment Metrics */

  @serializable(alias("total_appoinments", primitive()))
  totalAppoinments?: number;
  @serializable(alias("completed_appointments", primitive()))
  completedAppointments?: number;
  @serializable(alias("upcoming_appointments", primitive()))
  upcomingAppointments?: number;

  /* Dashboard Corporate Metrics */

  @serializable(alias("approved_corporates", primitive()))
  approvedCorporates?: number;
  @serializable(alias("pending_corporates", primitive()))
  pendingCorporates?: number;
  @serializable(alias("rejected_corporates", primitive()))
  rejectedCorporates?: number;

  /* Users Metrics */
  @serializable(alias("total_users", primitive()))
  totalUsers?: number;
  @serializable(alias("active_users", primitive()))
  activeUsers?: number;
  @serializable(alias("inactive_users", primitive()))
  inactiveUsers?: number;
  /* payment */
}
