import * as Yup from  "yup";

export const corporateFormValidation = Yup.object().shape({
    name: Yup.string()
        .required('Name is required!'),
    contactPerson: Yup.string()
        .required('Concat person is required!'),
    primaryEmail: Yup.string()
        .required('Email is required!')
        .email('Email is not valid!'),

});
