import {serializable, alias, primitive} from 'serializr';

export class Province {

    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('name', primitive()))
    name?: string;

}
