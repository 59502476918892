import React, { useState } from "react";
import CorporateDetail from "../../../shared/components/CorporateDetail";
import "./pendingCorporateDetail.scss";
import { Drawer } from "antd";
import CorporateVerificationForm from "../CorporateVerificationForm";

interface PendingCorporateDetailProps {
  match: any;
}

function PendingCorporateDetail(props: PendingCorporateDetailProps) {
  const { corporateId } = props.match.params;

  const [showCorporateVerifyForm, setShowCorporateVerifyForm] = useState(false);

  const handleVerifyCorporate = () => setShowCorporateVerifyForm(true);

  const handleCloseCorporateVerificationForm = () =>
    setShowCorporateVerifyForm(false);

  const corporateDetailProps = {
    allowVerify: true,
    onVerify: handleVerifyCorporate,
  };

  return (
    <div className="pending-corporate-detail">
      <CorporateDetail {...corporateDetailProps} />
      <Drawer
        title={
          <div>
            <h4>Approve/ Reject Corporate</h4>
          </div>
        }
        placement="right"
        maskClosable={false}
        onClose={handleCloseCorporateVerificationForm}
        visible={showCorporateVerifyForm}
        destroyOnClose={true}
        width="50%"
      >
        <CorporateVerificationForm
          corporateId={corporateId}
          onCancel={handleCloseCorporateVerificationForm}
        />
      </Drawer>
    </div>
  );
}

export default PendingCorporateDetail;
