import {serializable, alias, primitive} from 'serializr';

export class Attachment {

    @serializable(alias('id', primitive()))
    id?: string;

    @serializable(alias('attachment_url', primitive()))
    attachmentUrl?: string;

    @serializable(alias('issue_id', primitive()))
    issueId?: string;

    @serializable(alias('attachment', primitive()))
    attachment?: string;

}
