import { Button, Col, Row } from "antd";
import React, { FC } from "react";
import { Support } from "../../../../models/Support/support.model";
import moment from "moment";
import "./issueDetails.scss";
import { Formik, Form } from "formik";
import DropdownField from "../../../../shared/components/DropdownField";
import { SupportStatus } from "../../../../enums/supportStatus.enum";
import SupportService from "../../../../services/Support/support.service";

const issueOptions = [
  { value: SupportStatus.PENDING, label: "Pending" },
  { value: SupportStatus.COMPLETED, label: "completed" },
];

interface IssueDetailsProps {
  support?: Support;
  closeHandler?: (support: Support) => void;
}

const IssueDetails: FC<IssueDetailsProps> = (props) => {
  const { support, closeHandler } = props;

  const handleSubmit = (values: Support) => {
    const support = Object.assign(new Support(), values);
    SupportService.updateSubscription(
      support,
      (support) => {
        closeHandler && closeHandler(support);
      },
      () => {}
    );
  };

  return (
    <div className="issue-details">
      <Row>
        <Col span={8}>
          <label htmlFor="">Issue name</label>
          <p>{support?.issueName}</p>
        </Col>
        <Col span={16}>
          <label htmlFor="">Issue Type</label>
          <p>{support?.issueType}</p>
        </Col>
        <Col span={8}>
          <label htmlFor="">Priority</label>
          <p>{support?.priority}</p>
        </Col>
        <Col span={16}>
          <label htmlFor="">Practice</label>
          <p>{support?.practiceName}</p>
        </Col>
        <Col span={8}>
          <label htmlFor="">Staff</label>
          <p>{`${support?.staffFirstName} ${support?.staffLastName}`}</p>
        </Col>
        <Col span={16}>
          <label htmlFor="">Patient</label>
          <p>{`${support?.patientFirstName} ${support?.patientLastName}`}</p>
        </Col>
        <Col span={8}>
          <label htmlFor="">Raised Date</label>
          <p>{moment(support?.createdAt).format("MM/DD/YYYY")}</p>
        </Col>
        <Col span={16}>
          <label htmlFor="">Due Date</label>
          <p>{moment(support?.createdAt).format("MM/DD/YYYY")}</p>
        </Col>
        <Col span={24}>
          <label htmlFor="">Issue Description</label>
          <p>{support?.description}</p>
        </Col>
        {/* <Col span={8}>
          <label htmlFor="">Issue Description</label>
          <p>
            {support?.assignedToFirstName} {support?.assignedToLastName}
          </p>
        </Col> */}
      </Row>
      {support?.status === SupportStatus.PENDING && (
        <Formik initialValues={support} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <Row gutter={[20, 10]} justify="end">
                <Col>
                  <DropdownField
                    defaultValue={values.status}
                    options={issueOptions}
                    name="status"
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col>
                  <Button htmlType="submit" type="primary">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default IssueDetails;