import React, {ReactNode} from "react";
import "./badge.scss";

interface BadgeProps {
    children: ReactNode;
}

function Badge(props: BadgeProps) {
    return (
        <span className='app-badge'>
            { props.children }
        </span>
    );
}

export default Badge;
