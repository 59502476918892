import axiosInstance from "../../interceptor/axiosInstance";
import Subscription from "../../models/Subscription/subscription.model";
import ApiRoutes from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import SubscriptionFrequency from "../../models/SubscriptionFrequency/subscriptionFrequency.model";

// const subscriptions = Array(5).fill({
//   subscriptionName: "Subscription 1",
//   maxNoOfPatients: "500",
//   maxNoOfPractices: "10",
//   maxNoOfStaff: "100",
//   tenure: "",
//   monthlyAmount: "35",
//   yearlyAmount: "350",
//   trailPeriod: "30 days",
//   featureDescription:
//     "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis, assumenda accusantium natus cum asperiores suscipit. Tempore reiciendis aspernatur est dolore distinctio nobis et, corrupti assumenda?",
// });

export default class SubscriptionService {
  // List all subscriptions
  static async fetchSubscription(
    onSuccess: (subscriptions: Subscription[]) => void,
    onFinal: () => void
  ) {
    try {
      const { data } = await axiosInstance.get(ApiRoutes.SUBSCRIPTION);
      const subscriptions = deserialize(
        Subscription,
        data["subscriptions"] as Subscription[]
      );
      onSuccess(subscriptions);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  // Create subscription
  static async viewSubscription(
    subscriptionId: number,
    onSuccess: (subscription: Subscription) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.SUBSCRIPTION + "/" + subscriptionId;
      const { data } = await axiosInstance.get(URL);
      const subscription = deserialize(Subscription, data["subscription"]);
      onSuccess(subscription);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  // Create subscription
  static async createSubscription(
    subscription: Subscription,
    onSuccess: (subscription: Subscription) => void,
    onFinal: () => void
  ) {
    try {
      const subscriptionJSON = {
        subscription: serialize(subscription),
      };
      const { data } = await axiosInstance.post(
        ApiRoutes.SUBSCRIPTION,
        subscriptionJSON
      );
      const createdSubscription = deserialize(
        Subscription,
        data["subscription"]
      );
      onSuccess(createdSubscription);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  // Create subscription
  static async updateSubscription(
    subscription: Subscription,
    onSuccess: (subscription: Subscription) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.SUBSCRIPTION + "/" + subscription?.id;

      const subscriptionJSON = {
        subscription: serialize(subscription),
      };
      const { data } = await axiosInstance.put(URL, subscriptionJSON);
      const createdSubscription = deserialize(
        Subscription,
        data["subscription"]
      );
      onSuccess(createdSubscription);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async getSubscriptionByCorporate(
    corporateId: string,
    onSuccess: (subscriptionFrequency: SubscriptionFrequency) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.GET_CORPORATE_SUBSCRIPTION_FREQUENCY?.replace(
        ":corporateId",
        corporateId
      );
      const { data } = await axiosInstance.get(URL);
      const subscriptionFrequency = deserialize(
        SubscriptionFrequency,
        data["subscription_frequency"]
      );
      onSuccess(subscriptionFrequency);
    } catch (error) {
    } finally {
      onFinal();
    }
  }
}
