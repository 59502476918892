import { Button, Col, Row } from "antd";
import { Checkbox } from "antd";
import { Formik, Form } from "formik";
import React, { FC } from "react";
import { useState } from "react";
import { Tenure } from "../../../enums/tenure.enum";
import Subscription from "../../../models/Subscription/subscription.model";
import SubscriptionFrequency from "../../../models/SubscriptionFrequency/subscriptionFrequency.model";
import SubscriptionService from "../../../services/Subscription/subscription.service";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import "./subscriptionForm.scss";

interface SubscriptionFormProps {
  subscription: boolean | Subscription;
  successHandler: (subscription: Subscription) => void;
}

const tenureOptions = [
  {
    label: "Monthly",
    value: Tenure.MONTHLY,
  },
  {
    label: "Yearly",
    value: Tenure.YEARLY,
  },
];

const practices = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 20,
    value: 20,
  },
];

const staffs = [
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
];

const patients = [
  {
    label: 500,
    value: 500,
  },
  {
    label: 1000,
    value: 1000,
  },
];

const genSubscriptionFrequency = (
  name: Tenure,
  hasIncluded: Tenure[],
  amount?: string
) => {
  if (!amount || !hasIncluded.includes(name)) {
    return;
  }

  const subscriptionFrequency: SubscriptionFrequency = {
    subscriptionType: name,
  };

  subscriptionFrequency.recurringAmount = amount;

  return subscriptionFrequency;
};

const SubscriptionForm: FC<SubscriptionFormProps> = (props) => {
  const { subscription, successHandler } = props;

  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: Subscription) => {
    setLoading(true);
    const monthly = genSubscriptionFrequency(
      Tenure.MONTHLY,
      values?.tenure,
      values?.monthlyAmount
    );
    const yearly = genSubscriptionFrequency(
      Tenure.YEARLY,
      values?.tenure,
      values?.yearlyAmount
    );

    const SubscriptionFrequency = [];
    if (monthly) {
      SubscriptionFrequency.push(monthly);
    }

    if (yearly) {
      SubscriptionFrequency.push(yearly);
    }

    const createdSubscription = Object.assign(new Subscription(), {
      ...values,
      SubscriptionFrequency,
    });

    SubscriptionService[
      typeof subscription === "boolean"
        ? "createSubscription"
        : "updateSubscription"
    ](
      createdSubscription,
      (subscription) => {
        successHandler(subscription);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const formikProps = {
    initialValues:
      typeof subscription === "boolean" ? new Subscription() : subscription,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  };

  return (
    <div className="subscription-form">
      <Formik {...formikProps}>
        {({ values, setFieldValue }) => (
          <Form>
            <div className="wrapper">
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <InputField
                    title="Subscription name"
                    type="text"
                    name="subscriptionName"
                  />
                </Col>
                <Col span={12}>
                  {/* <DropdownField
                    title="Max no patients"
                    name="maxNoOfPatients"
                    value={values.maxNoOfPatients}
                    options={patients}
                    setFieldValue={setFieldValue}
                  /> */}
                  <InputField
                    type="text"
                    title="Max no patients"
                    name="maxNoOfPatients"
                  />
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  {/* <DropdownField
                    title="Max no practices"
                    name="maxNoOfPractices"
                    value={values.maxNoOfPractices}
                    options={practices}
                    setFieldValue={setFieldValue}
                  /> */}
                  <InputField
                    type="text"
                    title="Max no practices"
                    name="maxNoOfPractices"
                  />
                </Col>
                <Col span={12}>
                  {/* <DropdownField
                    title="Max no staff"
                    name="maxNoOfStaff"
                    value={values.maxNoOfStaff}
                    options={staffs}
                    setFieldValue={setFieldValue}
                  /> */}
                  <InputField
                    type="text"
                    title="Max no staff"
                    name="maxNoOfStaff"
                  />
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col>
                  <Checkbox.Group
                    options={tenureOptions}
                    value={values.tenure}
                    onChange={(tenure) => {
                      setFieldValue("tenure", tenure);
                    }}
                    className="mb-5"
                  />
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <InputField
                    title="Monthly Amount"
                    type="text"
                    name="monthlyAmount"
                    disabled={!values.tenure?.includes(Tenure.MONTHLY)}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    title="Yearly Amount"
                    type="text"
                    name="yearlyAmount"
                    disabled={!values.tenure?.includes(Tenure.YEARLY)}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <InputField
                    title="Trail Period"
                    type="text"
                    name="trailPeriod"
                  />
                </Col>
              </Row>
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <InputField
                    title="Feature description"
                    type="textarea"
                    name="featureDescription"
                    rows={4}
                  />
                </Col>
              </Row>
            </div>
            <div className="controller">
              <Button type="text">Cancel</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                {loading ? "Saving" : "Save"}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SubscriptionForm;
